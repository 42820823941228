export enum EPRIVATEROUTE {
  OVERVIEW = "/dashboard/overview",
  USER = "/dashboard/user",
  SETTING = "/dashboard/settings",
  SETTING_SUB_MENU = "/dashboard/settings/setting_sub_menu",
  PROFILE = "/dashboard/profile",
  DASHBOARD = "/dashboard",
  PROJECTS = "/dashboard/projects",
  PROJECTS_CREATE = "/dashboard/projects/create",
  EMPTY_PROJECT_LIST = "/dashboard/emptyProject",
  PROJECTS_EDIT = "/dashboard/projects/:project_id",
  VALIDATOR_PROVIDERS = "/dashboard/validator_providers",
  VALIDATOR_PROVIDERS_IMPORT = "/dashboard/validator_providers/import",
  VALIDATOR_PROVIDER_EDIT = "/dashboard/validator_providers/:validator_provider_id",
  VALIDATOR_PROVIDER_SHOW = "/dashboard/validator_providers/show/:validator_provider_id",
  VALIDATOR_PROVIDER_SHOW_ = "/dashboard/validator_providers/show",
  VALIDATORS = "/dashboard/validators",
  VALIDATOR_CREATE = "/dashboard/validators/create",
  VALIDATOR_SHOW = "/dashboard/validator/show/:validator_id",
  VALIDATOR_SHOW_ = "/dashboard/validator/show",
  VALIDATOR_EDIT = "/dashboard/validators/:validator_id",
  TYPES = "/dashboard/types",
  TYPES_CREATE = "/dashboard/types/create",
  TYPES_EDIT = "/dashboard/types/:type_id",
  SERVICE_DEFINITIONS = "/dashboard/service_definitions",
  SERVICE_DEFINITIONS_CREATE = "/dashboard/service_definitions/create",
  SERVICE_DEFINITIONS_EDIT = "/dashboard/service_definitions/:service_definitions_id",
}
