import {
  MAX_SERVICE_ENVIRONMENT,
  MAX_SERVICE_CONFIGS_COUNT,
  MAX_SERVICE_REST_RESP,
  MAX_SERVICE_PORTS_REMOTE_SERVICES,
  MAX_SERVICE_FUNCTIONALITY_COUNT,
  MAX_SERVICE_MAX_CONDITION_COUNT,
  MAX_SERVICE_PORTS_LISTENING_PORT,
} from "@src/constants/form";
import { TFunction } from "i18next";
import * as Yup from "yup";
Yup.addMethod(Yup.array, "unique", function (field: string, message: string) {
  return this.test("unique", message, function (array) {
    const uniqueData = Array.from(
      new Set(array?.map((row) => row[field]?.toLowerCase()))
    );
    const isUnique = array?.length === uniqueData.length;
    if (isUnique) {
      return true;
    }
    const index = array?.findIndex(
      (row, i) => row[field]?.toLowerCase() !== uniqueData[i]
    );
    if (array && index !== undefined && array[index][field] === "") {
      return true;
    }
    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message,
    });
  });
});

const templateSchema = (t: TFunction) => ({
  type: Yup.string().required(`${t("error__type is required")}`),
  template: Yup.string().required(`${t("error__template is required")}`),
  rest_resp_template: Yup.array()
    .max(
      MAX_SERVICE_REST_RESP,
      `${t("error__the maximum rest_resp_template that you can add are", {
        max_rest_resp_template: MAX_SERVICE_REST_RESP,
      })}`
    )
    .of(
      Yup.object().shape({
        template: Yup.string().required(`${t("error__template is required")}`),
        code: Yup.array()
          .required(`${t("error__code is required")}`)
          .min(
            1,
            `${t("error__at list one value required", {
              label: "code",
            })}`
          ),
      })
    ),
  env: Yup.array()
    .unique(
      "path",
      `${t(
        "error__this path is used on another object, please provide a unique path."
      )}`
    )
    .min(1, t("message__at least one environment setting is required")!)
    .max(
      MAX_SERVICE_ENVIRONMENT,
      `${t("error__the maximum environment that you can add are", {
        max_env: MAX_SERVICE_ENVIRONMENT,
      })}`
    )
    .of(
      Yup.object().shape({
        path: Yup.string().required(`${t("error__path is required")}`),
        from_section: Yup.string().required(
          `${t("error__from_section is required")}`
        ),
      })
    ),
});

export const Validation = (
  t: TFunction,
  isRenderImageForm: boolean,
  isRenderEnvForm: boolean
) => {
  return Yup.object().shape({
    name: Yup.string()
      .required(`${t("error__name is required")}`)
      .matches(
        /^\S+$/,
        `${t("error__the value should not have any white space")}`
      ),
    configs: Yup.array()
      .unique(
        "name",
        `${t(
          "error__this name is used on another object, please provide a unique name."
        )}`
      )
      .max(
        MAX_SERVICE_CONFIGS_COUNT,
        `${t("error__the maximum configs that you can add are", {
          max_configs_count: MAX_SERVICE_CONFIGS_COUNT,
        })}`
      )
      .of(
        Yup.object().shape({
          name: Yup.string()
            .required(`${t("error__name is required")}`)
            .matches(
              /^\S+$/,
              `${t("error__the value should not have any white space")}`
            ),

          description: Yup.string().required(
            `${t("error__description is required")}`
          ),
          type: Yup.string().required(`${t("error__type is required")}`),
        })
      ),
    renderers_env_file: isRenderEnvForm
      ? Yup.object().shape(templateSchema(t))
      : Yup.object().notRequired().nullable(),
    ports_remote_services: Yup.array()
      .unique(
        "name",
        `${t(
          "error__this name is used on another object, please provide a unique name."
        )}`
      )
      .max(
        MAX_SERVICE_PORTS_REMOTE_SERVICES,
        `${t("error__the maximum port remote services that you can add are", {
          ports_remote_services: MAX_SERVICE_PORTS_REMOTE_SERVICES,
        })}`
      )
      .of(
        Yup.object().shape({
          name: Yup.string()
            .required(`${t("error__name is required")}`)
            .matches(
              /^\S+$/,
              `${t("error__the value should not have any white space")}`
            ),

          network_stack: Yup.string().required(
            `${t("error__network stack is required")}`
          ),

          functionalities: Yup.array()
            .unique(
              "name",
              `${t(
                "error__this name is used on another object, please provide a unique name."
              )}`
            )
            .max(
              MAX_SERVICE_FUNCTIONALITY_COUNT,
              `${t("error__the maximum functionalities that you can add are", {
                max_functionalities_count: MAX_SERVICE_FUNCTIONALITY_COUNT,
              })}`
            )

            .of(
              Yup.object().shape({
                name: Yup.string()
                  .required(`${t("error__name is required")}`)
                  .matches(
                    /^\S+$/,
                    `${t("error__the value should not have any white space")}`
                  ),

                description: Yup.string().required(
                  `${t("error__description is required")}`
                ),
                path: Yup.string().required(`${t("error__path is required")}`),
                action: Yup.string().required(
                  `${t("error__action is required")}`
                ),
              })
            ),

          condition: Yup.array()
            .unique(
              "name",
              `${t(
                "error__this name is used on another object, please provide a unique name."
              )}`
            )
            .max(
              MAX_SERVICE_MAX_CONDITION_COUNT,
              `${t("error__the maximum conditions that you can add are", {
                max_condition_count: MAX_SERVICE_MAX_CONDITION_COUNT,
              })}`
            )
            .of(
              Yup.object().shape({
                name: Yup.string()
                  .required(`${t("error__name is required")}`)
                  .matches(
                    /^\S+$/,
                    `${t("error__the value should not have any white space")}`
                  ),

                where: Yup.string().required(
                  `${t("error__where is required")}`
                ),
                validator_type: Yup.string().required(
                  `${t("error__validator type is required")}`
                ),
                exposed_info: Yup.string().required(
                  `${t("error__exposed info is required")}`
                ),
                where_template: Yup.object().shape(templateSchema(t)),
                template: Yup.object().shape(templateSchema(t)),
              })
            ),
          network_stack_template: Yup.object().shape(templateSchema(t)),
          connection_count: Yup.object().shape({
            min: Yup.number().min(1, "Min must be at least 1"),
            max: Yup.number().min(1, "Max must be at least 1"),
          }),
        })
      ),
    ports_listening_ports: Yup.array()
      .unique(
        "name",
        `${t(
          "error__this name is used on another object, please provide a unique name."
        )}`
      )
      .max(
        MAX_SERVICE_PORTS_LISTENING_PORT,
        `${t("error__the maximum port listening port that you can add are", {
          ports_listening_ports: MAX_SERVICE_PORTS_LISTENING_PORT,
        })}`
      )
      .of(
        Yup.object().shape({
          name: Yup.string()
            .required(`${t("error__name is required")}`)
            .matches(
              /^\S+$/,
              `${t("error__the value should not have any white space")}`
            ),

          network_stack: Yup.string().required(
            `${t("error__network stack is required")}`
          ),

          functionalities: Yup.array()
            .unique(
              "name",
              `${t(
                "error__this name is used on another object, please provide a unique name."
              )}`
            )
            .max(
              MAX_SERVICE_FUNCTIONALITY_COUNT,
              `${t("error__the maximum functionalities that you can add are", {
                max_functionalities_count: MAX_SERVICE_FUNCTIONALITY_COUNT,
              })}`
            )

            .of(
              Yup.object().shape({
                name: Yup.string()
                  .required(`${t("error__name is required")}`)
                  .matches(
                    /^\S+$/,
                    `${t("error__the value should not have any white space")}`
                  ),

                description: Yup.string().required(
                  `${t("error__description is required")}`
                ),
                path: Yup.string().required(`${t("error__path is required")}`),
                action: Yup.string().required(
                  `${t("error__action is required")}`
                ),
              })
            ),

          condition: Yup.array()
            .unique(
              "name",
              `${t(
                "error__this name is used on another object, please provide a unique name."
              )}`
            )
            .max(
              MAX_SERVICE_MAX_CONDITION_COUNT,
              `${t("error__the maximum conditions that you can add are", {
                max_condition_count: MAX_SERVICE_MAX_CONDITION_COUNT,
              })}`
            )
            .of(
              Yup.object().shape({
                name: Yup.string()
                  .required(`${t("error__name is required")}`)
                  .matches(
                    /^\S+$/,
                    `${t("error__the value should not have any white space")}`
                  ),

                where: Yup.string().required(
                  `${t("error__where is required")}`
                ),
                validator_type: Yup.string().required(
                  `${t("error__validator type is required")}`
                ),
                exposed_info: Yup.string().required(
                  `${t("error__exposed info is required")}`
                ),
                where_template: Yup.object().shape(templateSchema(t)),
                template: Yup.object().shape(templateSchema(t)),
              })
            ),
          network_stack_template: Yup.object().shape(templateSchema(t)),
          connection_count: Yup.object().shape({
            min: Yup.number().min(1, "Min must be at least 1"),
            max: Yup.number().min(1, "Max must be at least 1"),
          }),
        })
      ),
    renderers_image: isRenderImageForm
      ? Yup.object().shape(templateSchema(t))
      : Yup.string().required(`${t("error__renderers image is required")}`),
    variables: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(`${t("error__name is required")}`),
        type: Yup.string().required(`${t("error__type is required")}`),
        value_template: Yup.lazy((value) => {
          const valueType = typeof value;
          if (valueType === "string") {
            return Yup.string().required(
              `${t("error__value template is required")}`
            );
          } else if (valueType === "object") {
            return Yup.object().shape(templateSchema(t));
          } else {
            return Yup.mixed().notRequired();
          }
        }),
      })
    ),
  });
};
