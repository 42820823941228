import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@redux/store";
import { IProject } from "@src/models/project.model";

export interface IProjectState {
  selectedProject: IProject;
}

const initialState: IProjectState = {
  selectedProject: { name: "", title: "", id: "" },
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setSelectedProject: (state, action: PayloadAction<IProject>) => {
      state.selectedProject = action.payload;
    },
  },
});

export const { setSelectedProject } = projectSlice.actions;

export const getProject = (state: RootState) => state.project.selectedProject;

export const projectReducer = projectSlice.reducer;
