import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "@redux/store";
export interface IProfile {
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  given_name?: string;
  name?: string;
  preferred_username?: string;
  sub?: string;
}
export interface IUserState {
  profile: IProfile;
}

const initialState: IUserState = {
  profile: {
    email: "",
    email_verified: false,
    family_name: "",
    given_name: "",
    name: "",
    preferred_username: "",
    sub: "",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IProfile>) => {
      state.profile = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export const getUser = (state: RootState) => state.user.profile;

export const userReducer = userSlice.reducer;
