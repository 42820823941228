import { colDef } from "@src/helpers/utils";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  IColumn,
  ITableAction,
} from "@src/components/entity_table/entity_table";
import {
  TYPE_NESTED_FIELD,
  TypeRepository,
} from "@src/services/type.repository";
import { IType, ITypeFilter } from "@src/models/type.model";
import { ITableBulkAction } from "@src/components/type/tabel-bulk-action-type";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { Filter } from "@src/containers/private/type/filter";
import { EntityNotFoundException } from "@src/services/repository";
import { ConfirmModal } from "@src/components/confirm_modal";
import { EMODAL_ACTION } from "@src/constants/enum/confirm-modal-action";
import { GenericList } from "@src/components/generic-list/generic-list";
import { HeaderList } from "@src/containers/private/type/header";
export interface IProps {
  typeRepo?: TypeRepository;
}

export const Types: React.FC<IProps> = ({ typeRepo }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [bulkSelected, setBulkSelected] = useState<Array<IType>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedDeleteData, setSelectedDeleteData] = useState<IType>();
  const [repo, setRepo] = useState<TypeRepository>(
    typeRepo || new TypeRepository()
  );

  const fields: Array<keyof ITypeFilter> = [
    "name",
    "title",
    "since",
    "before",
    "page_size",
    "validator_id",
  ];
  const query: ITypeFilter = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields.forEach((x) => ((query as any)[x] = searchParams.get(x)));

  useEffect(() => {
    setRepo(typeRepo || repo);
  }, [typeRepo != null]);

  const bulkAction: Array<ITableBulkAction> = [
    {
      icon: <DeleteIcon />,
      title: t("delete"),
      isDisabled: false,
      callBack: () => {
        return;
      },
    },
  ];

  const columns: IColumn<IType>[] = [
    colDef("name", t("name")),
    colDef("title", t("title")),
    colDef("validator_id", t("page__validator__validator"), (_, y) => (
      <Link to={`${EPRIVATEROUTE.VALIDATOR_SHOW_}/${y.validator_id}`}>
        {y.validator_detail?.name}
      </Link>
    )),
  ];

  const deleteType = async () => {
    setLoading(true);
    setError(false);
    try {
      const res = await repo.delete(selectedDeleteData!);
      if (res) {
        toast.success(
          t("message__your request has been successfully completed")
        );
        setRefresh(!refresh);
      }
    } catch (error) {
      if (error instanceof EntityNotFoundException) {
        toast.success(
          t("message__your request has been successfully completed")
        );
        return;
      }
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };
  const getActionModal = (data: EMODAL_ACTION) => {
    switch (data) {
      case EMODAL_ACTION.YES:
        deleteType();
        break;
      default:
        setShowDeleteModal(false);
    }
  };

  const actionList: Array<ITableAction<IType>> = [
    {
      icon: <DeleteIcon />,
      title: t("delete"),
      action: (data: IType) => {
        setShowDeleteModal(true);
        setSelectedDeleteData(data);
      },
    },
    {
      icon: <ModeEditIcon />,
      title: t("edit"),
      action: (data: IType) => {
        navigate(`${EPRIVATEROUTE.TYPES}/${data.id}`);
      },
    },
  ];
  return (
    <Fragment>
      <Helmet>
        <title>{t("page__type__types")}</title>
      </Helmet>
      <ToastContainer />
      <GenericList
        stateError={[error, setError]}
        stateLoading={[loading, setLoading]}
        stateSelectedItems={[bulkSelected, setBulkSelected]}
        bulkActionList={bulkAction}
        bulkSelectedMsgTemplate={
          bulkSelected.length > 1
            ? t("page__type__typesSelected", {
                num: bulkSelected.length,
              })
            : t("page__type__typeSelected", {
                num: bulkSelected.length,
              })
        }
        columns={columns}
        HeaderComponent={HeaderList}
        FilterComponent={Filter}
        initQuery={query}
        nestedFields={[TYPE_NESTED_FIELD.VALIDATOR]}
        repo={repo}
        rowActionList={actionList}
      />
      <ConfirmModal
        show={showDeleteModal}
        title={t("delete name", {
          name: selectedDeleteData?.name,
        })}
        text={t("are you sure to delete", {
          name: selectedDeleteData?.name,
        })}
        callBack={getActionModal}
        yes={"delete"}
        no={"cancel"}
      />
    </Fragment>
  );
};
