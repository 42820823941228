/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Validation } from "@containers/private/service-definition/form/validation";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";
import { IEntityFieldError } from "@src/services/repository";
import { IServiceDefinition } from "@src/models/service-definition.model";
import { IStep, VerticalWizard } from "@components/form/vertical-wizard";
import { RenderersImage } from "@src/containers/private/service-definition/form/renderers-image-sub-form";
import { InformationCard } from "@src/containers/private/service-definition/form/information-card";
import { Configuration } from "@src/components/form/configuration-sub-form";
import {
  IServiceFormData,
  IVariableFormData,
} from "@src/containers/private/service-definition/model/form.model";
import { RenderersEnvCard } from "@src/containers/private/service-definition/form/renderers-env-sub-form";
import { PortCard } from "@src/containers/private/service-definition/form/port-card";
import { Variables } from "@src/components/form/variables-sub-form";
import { convertObjToArray } from "@src/helpers/utils";

export interface IProps {
  onSubmit: (formValues: IServiceFormData) => void;
  editMode: boolean;
  loading: boolean;
  model: IServiceDefinition | null;
  errorList: Array<IEntityFieldError<IServiceDefinition>> | null;
}

export const SLServiceDefinitionForm: React.FC<IProps> = ({
  onSubmit,
  editMode,
  loading,
  model,
  errorList,
}) => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );
  const [isRenderImageForm, setIsRenderImageForm] = useState<string>("false");
  const [isRenderEnvForm, setIsRenderEnvForm] = useState<boolean>(false);
  const methods = useForm<IServiceFormData>({
    resolver: yupResolver(
      Validation(t, isRenderImageForm === "true", isRenderEnvForm)
    ),
    mode: "onBlur",
  });
  const {
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = methods;
  const handleSetError = (error: IEntityFieldError<IServiceDefinition>) => {
    const path = error.loc.filter((item) => item !== "body").join(".") as any;
    setError(path, { message: error.msg });
  };
  useEffect(() => {
    if (editMode && model) {
      if (model.labels) {
        const labelsObject = model.labels;
        const convertedLabelsArray = convertObjToArray(labelsObject);
        setValue(
          "labels",
          convertedLabelsArray as [{ key: string | null; value: string | null }]
        );
      }
      if (model.variables) {
        const transformedVariables = model.variables.map((variable) => {
          if (variable.value) {
            const { value, ...rest } = variable;
            const formVariable = {
              ...rest,
              value_template: value,
            };
            return formVariable;
          } else {
            const { value_template, ...rest } = variable;
            const formVariable = {
              ...rest,
              value_template: value_template,
            };
            return formVariable;
          }
        });

        setValue("variables", transformedVariables as Array<IVariableFormData>);
      }
      setIsRenderImageForm(
        typeof model.renderers_image !== "string" ? "true" : "false"
      );
      setValue("name", model.name);
      setValue("title", model.title);
      setValue("description", model.description);
      setValue("renderers_image", model.renderers_image);
      setValue("tags", model.tags);
      setValue("ports_remote_services", model.ports_remote_services);
      setValue("ports_listening_ports", model.ports_listening_ports);
      setValue("volumes", model.volumes);
      setValue("configs", model.configs);

      setValue("renderers_env_file", model.renderers_env_file);
    }
  }, [editMode, model]);

  useEffect(() => {
    if (errorList) {
      errorList.forEach((error) => {
        if (error.loc && error.loc.length > 0 && error.loc[0] === "body") {
          handleSetError(error);
        }
      });
    }
  }, [errorList]);
  const isValid = Object.keys(errors).length == 0;
  const btnTitle = editMode ? t("edit") : t("create");
  const steps: IStep[] = [
    {
      stepTitle: (
        <>
          <div>{t("page__service__information")}</div>
          <div className={`${errors.name ? "wizard-error" : ""}`}>
            {errors.name ? t("error__you have some error field") : ""}
          </div>
        </>
      ),
      component: <InformationCard />,
    },
    {
      stepTitle: (
        <>
          <div>{t("page__service__configuration")}</div>
          <div className={`${errors.configs ? "wizard-error" : ""}`}>
            {errors.configs?.message
              ? errors.configs?.message
              : errors.configs?.length
              ? t("error__you have some error field")
              : ""}
          </div>
        </>
      ),
      component: <Configuration editMode={editMode} model={model!} />,
    },

    {
      stepTitle: (
        <>
          <div>{t("page__service__renderers image")}</div>
          <div className={`${errors.renderers_image ? "wizard-error" : ""}`}>
            {errors.renderers_image?.message
              ? errors.renderers_image?.message
              : errors.renderers_image
              ? t("error__you have some error field")
              : ""}
          </div>
        </>
      ),
      component: (
        <RenderersImage
          value={isRenderImageForm}
          onChange={setIsRenderImageForm}
        />
      ),
    },
    {
      stepTitle: (
        <>
          <div>{t("page__service__variable")}</div>
          <div className={`${errors.variables ? "wizard-error" : ""}`}>
            {errors.variables?.message
              ? errors.variables?.message
              : errors.variables?.length
              ? t("error__you have some error field")
              : ""}
          </div>
        </>
      ),
      component: <Variables editMode={editMode} model={model!} />,
    },
    {
      stepTitle: (
        <>
          <div>{t("page__service__renderers env file")}</div>
          <div className={`${errors.renderers_env_file ? "wizard-error" : ""}`}>
            {errors.renderers_env_file?.message
              ? errors.renderers_env_file?.message
              : errors.renderers_env_file
              ? t("error__you have some error field")
              : ""}
          </div>
        </>
      ),
      component: (
        <RenderersEnvCard
          value={isRenderEnvForm}
          onChange={setIsRenderEnvForm}
        />
      ),
    },
    {
      stepTitle: (
        <>
          <div>{t("ports remote services")}</div>
          <div
            className={`${errors.ports_remote_services ? "wizard-error" : ""}`}
          >
            {errors.ports_remote_services?.message
              ? errors.ports_remote_services?.message
              : errors.ports_remote_services
              ? t("error__you have some error field")
              : ""}
          </div>
        </>
      ),
      component: (
        <PortCard title="ports remote services" type="ports_remote_services" />
      ),
    },
    {
      stepTitle: (
        <>
          <div>{t("ports listening ports")}</div>
          <div
            className={`${errors.ports_listening_ports ? "wizard-error" : ""}`}
          >
            {errors.ports_listening_ports?.message
              ? errors.ports_listening_ports?.message
              : errors.ports_listening_ports
              ? t("error__you have some error field")
              : ""}
          </div>
        </>
      ),
      component: (
        <PortCard title="ports listening ports" type="ports_listening_ports" />
      ),
    },
  ];
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div data-testid="stateless-form-component" className="container">
            <div className="button-container">
              <Button
                type="submit"
                style={{ marginRight: "4px" }}
                data-testid="create-form-service"
                disabled={!isValid || loading}
                variant="contained"
              >
                {loading ? t("loading") : btnTitle}
              </Button>

              <Button
                variant="outlined"
                component={Link}
                to={`${EPRIVATEROUTE.SERVICE_DEFINITIONS}?selectedProject=${selectedProject.name}`}
              >
                {t("cancel")}
              </Button>
            </div>
            <VerticalWizard steps={steps} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
