import React, { useState } from "react";

export interface IStep {
  stepTitle: JSX.Element;
  component: JSX.Element;
}
interface IProps {
  steps: Array<IStep>;
}
export const VerticalWizard: React.FC<IProps> = ({ steps }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const goToStep = (step: number) => {
    setCurrentStep(step);
  };

  return (
    <div className="wizard-container" data-testid="vertical-wizard">
      <div className="wizard-sidebar">
        {steps.map((step: IStep, index: number) => (
          <div
            key={`${index}vertical-wizard-sidebar`}
            className={`wizard-step ${currentStep === index ? "active" : ""}`}
            onClick={() => goToStep(index)}
            data-testid={`${index + 1} vertical-wizard-step`}
          >
            {step.stepTitle}
          </div>
        ))}
      </div>
      <div className="wizard-content">
        {steps.map((step: IStep, index: number) => (
          <div
            key={`${index}vertical-wizard-component`}
            className={`step-content ${currentStep === index ? "active" : ""}`}
            data-testid={`${index + 1} vertical-wizard-component`}
          >
            {step.component}
          </div>
        ))}
      </div>
    </div>
  );
};
