import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { TypeInput } from "@src/components/form/type-input";
import { useEffect } from "react";
import { TitleWithHelpLink } from "@src/components/form/title-with-help-link";
import { emptyFunctionality } from "@containers/private/constant/empty-functionality";
import { AccordionComponent } from "@src/components/accordion/accordion";
interface IProps {
  type: string;
  disabled: boolean;
}
export function FunctionalityForm({ type, disabled }: IProps) {
  const { t } = useTranslation();
  const { control, trigger } = useFormContext();
  const {
    fields: functionalities,
    append: appendFunctionalities,
    remove: removeFunctionalities,
  } = useFieldArray({
    control,
    name: `${type}.functionalities`,
  });
  useEffect(() => {
    trigger(`${type}.functionalities`);
  }, [functionalities]);
  const functionalityName = useWatch({
    name: `${type}.functionalities`,
    control,
  });
  return (
    <Grid item data-testid="functionality-wrapper">
      <div className="functionality-wrapper">
        <Grid item className="label-wrapper">
          <TitleWithHelpLink
            title="page__service__functionality"
            error={disabled}
          />
        </Grid>
        {functionalities.map((functionality, index) => (
          <AccordionComponent
            className="accordion-summary"
            key={functionality.id}
            id="functionality"
            index={index}
            header={`${t("page__service__functionality")}: ${
              functionalityName?.[index]?.name || "(__)"
            }`}
            onDelete={removeFunctionalities}
          >
            <Grid
              container
              spacing={2}
              alignItems="center"
              className="label-input"
            >
              <Grid item xs={10} md={6}>
                <TypeInput
                  name={`${type}.functionalities.${index}.name`}
                  label={t("name")}
                />
              </Grid>
              <Grid item xs={10} md={6}>
                <TypeInput
                  name={`${type}.functionalities.${index}.description`}
                  label={t("description")}
                />
              </Grid>
              <Grid item xs={10} md={6}>
                <TypeInput
                  name={`${type}.functionalities.${index}.path`}
                  label={t("page__service__path")}
                />
              </Grid>
              <Grid item xs={10} md={6}>
                <TypeInput
                  name={`${type}.functionalities.${index}.action`}
                  label={t("page__service__action")}
                />
              </Grid>
            </Grid>
          </AccordionComponent>
        ))}
        <Button
          className="add-button-in-accordion"
          fullWidth
          disabled={disabled}
          onClick={() => {
            appendFunctionalities(emptyFunctionality);
          }}
        >
          {t("page__service__add functionality")}
        </Button>
      </div>
    </Grid>
  );
}
