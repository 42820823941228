import { EntityNotFoundException } from "@src/services/repository";
import { ValidatorProviderRepository } from "@src/services/validator-provider.repository";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FORM_TOAST_TIMEOUT } from "@src/constants/form";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Loading } from "@src/components/loading";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { EBACKGROUND_TASK_STATUS } from "@src/constants/enum/background-task-status";
import { IShowValidatorProvider } from "./model/form.model";
import { ILabelUi } from "@src/containers/model/ui-label.model";

export interface IProps {
  validatorProviderRepo?: ValidatorProviderRepository;
}
export const ShowValidatorProvider: React.FC<IProps> = ({
  validatorProviderRepo,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [validatorProvider, setValidatorProvider] =
    useState<IShowValidatorProvider>({
      id: "",
      background_task_status: EBACKGROUND_TASK_STATUS.DONE,
      project_id: "",
      description: "",
      endpoint: "https://example.co/",
      name: "",
      title: "",
      labels: [{ key: "", value: "" }],
      tags: [],
    });
  const [repo, setRepo] = useState<ValidatorProviderRepository>(
    validatorProviderRepo || new ValidatorProviderRepository()
  );
  const getValidatorProviderById = async (id: string) => {
    setLoading(true);
    try {
      const { data: validatorPro } = await repo.getById(id);
      const labelsObject = validatorPro.labels || {};

      const convertedLabelsArray = Object.entries(labelsObject).map(
        ([key, value]) => ({ key, value })
      );
      setValidatorProvider({ ...validatorPro, labels: convertedLabelsArray });
    } catch (errors) {
      if (!navigator.onLine) {
        toast.error(t("error__network_error"));
        return;
      }
      if (errors instanceof EntityNotFoundException) {
        toast.error(
          t("message__validator provider is not found", validatorProvider.id)
        );
        return;
      } else {
        toast.error(
          t(
            "message__an unexpected error happened, please try again after awhile"
          )
        );
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setRepo(validatorProviderRepo || repo);
  }, [validatorProviderRepo != null]);
  useEffect(() => {
    if (
      params.validator_provider_id &&
      params.validator_provider_id !== "undefined" &&
      params.validator_provider_id !== "null"
    ) {
      getValidatorProviderById(params.validator_provider_id);
    } else {
      toast.error(t("message__id is not valid"));
    }
  }, [params.validator_provider_id]);
  return (
    <Fragment>
      <Helmet>
        <title>{t("page__validatorProvider__show")}</title>
      </Helmet>
      <ToastContainer autoClose={FORM_TOAST_TIMEOUT} />
      {loading ? (
        <Loading />
      ) : (
        <div className="create-validator-provider-wrapper">
          <Grid container spacing={3} data-testid="validator-provider-wrapper">
            <Grid item xs={12} md={6}>
              <form>
                <Grid container spacing={2} rowSpacing={3} direction="column">
                  <Grid item>
                    <TextField
                      value={validatorProvider.name}
                      label={t("name")}
                      id="name-validatorPro"
                      placeholder={t("name")!}
                      type="text"
                      variant="outlined"
                      fullWidth
                      aria-readonly
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      value={validatorProvider.title}
                      label={t("title")}
                      id="title-validatorPro"
                      placeholder={t("title")!}
                      type="text"
                      variant="outlined"
                      fullWidth
                      aria-readonly
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      value={validatorProvider.description || ""}
                      multiline
                      label={t("description")}
                      id="description-validatorPro"
                      variant="outlined"
                      type="text"
                      fullWidth
                      rows={3}
                      aria-readonly
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      value={validatorProvider.endpoint}
                      label={t("page__validatorProvider__endpoint")}
                      id="endpoint-validatorPro"
                      placeholder={t("page__validatorProvider__endpoint")!}
                      type="text"
                      variant="outlined"
                      fullWidth
                      aria-readonly
                    />
                  </Grid>
                  {validatorProvider.tags?.length && (
                    <Grid item>
                      <Stack
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        justifyContent="start"
                      >
                        <Typography>{t("tags")}</Typography>
                      </Stack>
                      {validatorProvider.tags?.map(
                        (tag: string, index: number) => {
                          return (
                            <Fragment
                              key={`validator__provider__tag__${index}`}
                            >
                              <span>{tag}</span>
                              {index + 1 !== validatorProvider.tags?.length ? (
                                <span>-</span>
                              ) : (
                                <></>
                              )}
                            </Fragment>
                          );
                        }
                      )}
                    </Grid>
                  )}
                  {validatorProvider.labels?.length && (
                    <Grid item>
                      <Stack
                        direction="row"
                        spacing={3}
                        alignItems="center"
                        justifyContent="start"
                      >
                        <Typography>{t("labels")}</Typography>
                      </Stack>
                      {validatorProvider.labels?.map(
                        (label: ILabelUi, index: number) => {
                          return (
                            <Grid
                              key={`validator_provider_label${index}`}
                              container
                              spacing={2}
                              alignItems={"center"}
                              className="label-input"
                            >
                              <Grid item xs={10} md={5}>
                                <TextField
                                  value={label.key}
                                  fullWidth
                                  aria-readonly
                                  type="text"
                                  label={t("key")}
                                />
                              </Grid>
                              <Grid item xs={10} md={5}>
                                <TextField
                                  value={label.value}
                                  fullWidth
                                  aria-readonly
                                  type="text"
                                  label={t("value")}
                                />
                              </Grid>
                            </Grid>
                          );
                        }
                      )}

                      <Divider className="divider" />
                    </Grid>
                  )}
                  <Divider className="divider" />
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Button
                      data-testid="back-button"
                      variant="outlined"
                      onClick={() => window.history.back()}
                    >
                      <ArrowBackIcon />
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>

            <Grid item xs={12} md={6} lineHeight={2}>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Monthly Estimate
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui,
                  laudantium voluptas optio, ut repellat eum suscipit nulla
                  quidem
                </Typography>
                <Typography
                  variant="subtitle2"
                  lineHeight={2}
                  paddingBottom={2}
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Dolorum alias pariatur dolores commodi facilis modi
                  exercitationem doloremque eum deserunt accusantium distinctio
                  temporibus dolor a, expedita perferendis quasi reiciendis.
                  Ipsam, sit!
                </Typography>
                <Button variant="text" component={Link} to={""}>
                  Compute Engine Pricing
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      )}
    </Fragment>
  );
};
