import { CircularProgress, Container, Stack } from "@mui/material";
import React from "react";

export function Loading() {
  return (
    <Container className="loading" data-testid="loading-component">
      <Stack direction="column" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    </Container>
  );
}
