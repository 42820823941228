import React from "react";
import { Container, Stack, Typography } from "@mui/material";
import errorImage from "@src/assets/images/error/connection-error.svg";
import { useTranslation } from "react-i18next";

export function Error() {
  const { t } = useTranslation();

  return (
    <Container>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        textAlign="center"
      >
        <Typography data-testid="error-message">
          {t(
            "error__unfortunately we received an unexpected error please try after awhile"
          )}
        </Typography>
        <img alt="image-error" src={errorImage} width="6%" height="6%" />
      </Stack>
    </Container>
  );
}
