import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { EMODAL_ACTION } from "@src/constants/enum/confirm-modal-action";

interface IProps {
  show: boolean;
  title: string;
  text: string;
  callBack: (data: EMODAL_ACTION) => void;
  yes: string;
  no: string;
}
export function ConfirmModal(props: IProps) {
  const { show, title, text, callBack, yes, no } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth={true}
      open={show}
      onClose={() => callBack(EMODAL_ACTION.CLOSE)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t(`${title}`)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(`${text}`)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => callBack(EMODAL_ACTION.NO)}
          data-testid="confirm-disagree"
        >
          {t(no)}
        </Button>
        <Button
          onClick={() => callBack(EMODAL_ACTION.YES)}
          autoFocus
          data-testid="confirm-agree"
        >
          {t(yes)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
