import { useRef, useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ITableAction } from "@src/components/entity_table/entity_table";
import { IEntityModel } from "@src/models/entity-model.model";
interface IProps<T> {
  data: T;
  actionList: Array<ITableAction<T>>;
}

export function TableActionMenu<T extends IEntityModel>(props: IProps<T>) {
  const { data, actionList } = props;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <IconButton
        ref={ref}
        aria-label="table-action"
        onClick={() => setIsOpen(true)}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        data-testid="table-action-menu"
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {actionList.map((act: ITableAction<T>) => {
          return (
            <MenuItem
              disabled={act.isDisabled ? act.isDisabled(data) : false}
              onClick={() => {
                act.action(data);
              }}
              key={`table-action_item${act.title}_${data.id}`}
              aria-label={`${act.title}_${data.id}`}
              data-testid={`${act.title}_${data.id}`}
            >
              <ListItemIcon>{act.icon}</ListItemIcon>
              <ListItemText
                role={act.title}
                primary={act.title}
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
