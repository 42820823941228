import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { TypeInput } from "@src/components/form/type-input";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "@src/components/form/form-select";
import { from_sectionOptions } from "@containers/private/service-definition/form/constant";
import { emptyEnv } from "@constants/empty-env";
import { TitleWithHelpLink } from "@src/components/form/title-with-help-link";
interface IProps {
  type: string;
  disabled: boolean;
}
export const EnvSubForm = ({ type, disabled }: IProps) => {
  const { t } = useTranslation();
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();
  const {
    fields: envFields,
    append: appendEnv,
    remove: removeEnv,
  } = useFieldArray({
    control,
    name: `${type}.env`,
  });
  useEffect(() => {
    trigger(`${type}.env`);
  }, [envFields]);
  return (
    <Grid
      container
      spacing={2}
      rowSpacing={3}
      direction="column"
      data-testid={`${type}_environment`}
    >
      <TitleWithHelpLink
        title="page__service__environment"
        error={!!errors.renderers_image?.env?.length}
      />
      <Grid item>
        <Typography variant="subtitle2" color="primary">
          {t("message__at least one environment setting is required")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        {envFields.map((field, index) => {
          return (
            <Grid
              key={field.id}
              container
              spacing={2}
              rowSpacing={1}
              direction="column"
            >
              <Grid item>
                <FormSelect
                  label={t("page__service__from_section")}
                  name={`${type}.env.${index}.from_section`}
                  options={from_sectionOptions}
                />
              </Grid>
              <Grid item>
                <TypeInput
                  name={`${type}.env.${index}.path`}
                  label={t("page__service__path")}
                />
              </Grid>
              <Grid item>
                <TypeInput
                  name={`${type}.env.${index}.alias`}
                  label={t("page__service__alias")}
                />
                <IconButton color="inherit" onClick={() => removeEnv(index)}>
                  <DeleteIcon />
                </IconButton>
                <Divider className="divider" />
              </Grid>
            </Grid>
          );
        })}
        <Button
          variant="contained"
          className="label-button"
          disabled={disabled}
          onClick={() => {
            appendEnv(emptyEnv);
          }}
        >
          {t("page__service__+ Add environment")}
        </Button>
      </Grid>
    </Grid>
  );
};
