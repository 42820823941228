import { IPort } from "@src/models/service-definition.model";

export const emptyPort: IPort = {
  name: "",
  network_stack: "",
  network_stack_template: {
    template: "",
    type: "",
    env: [
      {
        from_section: "",
        path: "",
        alias: "",
      },
    ],
  },
  connection_count: { min: 0, max: 0 },
};
