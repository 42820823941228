import axios, {
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseHeaders,
} from "axios";
import { useToken } from "@helpers/hook/useToken";

export interface IHttpResponse<T> {
  status: number;
  headers: Map<string, string>;
  body: T;
}

export interface IHttpClient {
  get<D>(url: string, headers?: Map<string, string>): Promise<IHttpResponse<D>>;
  delete<D>(
    url: string,
    headers?: Map<string, string>
  ): Promise<IHttpResponse<D>>;
  post<B, D>(
    url: string,
    body: B,
    headers?: Map<string, string>
  ): Promise<IHttpResponse<D>>;
  put<B, D>(
    url: string,
    body: B,
    headers?: Map<string, string>
  ): Promise<IHttpResponse<D>>;
}

export class SimpleHttpClient implements IHttpClient {
  axios: AxiosInstance;

  constructor(timeout = 60000, axios_instance?: AxiosInstance) {
    axios_instance =
      axios_instance ||
      axios.create({
        timeout: timeout,
      });
    this.axios = axios_instance;
  }

  headersToMap(headers: AxiosResponseHeaders): Map<string, string> {
    const rtn: Map<string, string> = new Map();
    for (const k in headers) {
      rtn.set(k, headers[k]);
    }
    return rtn;
  }

  getHeaders(headers?: Map<string, string>): AxiosHeaders {
    const hs = new AxiosHeaders();
    headers?.forEach((value, key) => {
      hs[key] = value;
    });

    return hs;
  }
  getAxiosConfig<D>(headers?: Map<string, string>): AxiosRequestConfig<D> {
    return {
      headers: this.getHeaders(headers),
      validateStatus: (status: number) => {
        // Resolve only if the status code is less not server Errro or Request is not UnAuthoized
        return status < 500 && status != 401 && status != 403;
      },
    };
  }
  async get<D>(
    url: string,
    headers?: Map<string, string>
  ): Promise<IHttpResponse<D>> {
    const rtn = await this.axios.get<D, AxiosResponse<D, D>, D>(
      url,
      this.getAxiosConfig(headers)
    );
    return {
      status: rtn.status,
      headers: this.headersToMap(rtn.headers as AxiosResponseHeaders),
      body: rtn.data,
    };
  }
  async delete<D>(
    url: string,
    headers?: Map<string, string>
  ): Promise<IHttpResponse<D>> {
    const rtn = await this.axios.delete<D, AxiosResponse<D, D>, D>(
      url,
      this.getAxiosConfig(headers)
    );

    return {
      status: rtn.status,
      headers: this.headersToMap(rtn.headers as AxiosResponseHeaders),
      body: rtn.data,
    };
  }
  async post<B, D>(
    url: string,
    body: B,
    headers?: Map<string, string>
  ): Promise<IHttpResponse<D>> {
    const rtn = await this.axios.post<D, AxiosResponse<D, D>, B>(
      url,
      body,
      this.getAxiosConfig(headers)
    );

    return {
      status: rtn.status,
      headers: this.headersToMap(rtn.headers as AxiosResponseHeaders),
      body: rtn.data,
    };
  }
  async put<B, D>(
    url: string,
    body: B,
    headers?: Map<string, string>
  ): Promise<IHttpResponse<D>> {
    const rtn = await this.axios.put<D, AxiosResponse<D, D>, B>(
      url,
      body,
      this.getAxiosConfig(headers)
    );

    return {
      status: rtn.status,
      headers: this.headersToMap(rtn.headers as AxiosResponseHeaders),
      body: rtn.data,
    };
  }
}

export class AuthorizedHttpClient extends SimpleHttpClient {
  getHeaders(headers?: Map<string, string>): AxiosHeaders {
    const hs = super.getHeaders(headers);
    if (!hs.hasAuthorization()) {
      const idToken = useToken;
      hs["Authorization"] = `Bearer ${idToken}`;
    }
    return hs;
  }
}

export const defaultSimpleHttpClient = new SimpleHttpClient();
