import { ICondition } from "@src/models/service-definition.model";
export const emptyCondition: ICondition = {
  name: "",
  where: "",
  validator_type: "",
  exposed_info: "",
  where_template: {
    template: "",
    type: "",
    env: [
      {
        from_section: "",
        path: "",
        alias: "",
      },
    ],
  },
  template: {
    template: "",
    type: "",
    env: [
      {
        from_section: "",
        path: "",
        alias: "",
      },
    ],
  },
};
