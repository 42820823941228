import { Grid } from "@mui/material";
import { TypeInput } from "@src/components/form/type-input";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
export const InformationCard: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Grid container spacing={3} rowSpacing={3}>
        <Grid item xs={12} md={6}>
          <Grid
            container
            spacing={2}
            rowSpacing={3}
            direction="column"
            data-testid="service-information-component"
          >
            <Grid item>
              <TypeInput label={t("name")} name="name" />
            </Grid>
            <Grid item>
              <TypeInput label={t("title")} name="title" />
            </Grid>
            <Grid item>
              <TypeInput
                name="description"
                label={t("description")}
                multiline
                rows={5}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};
