import { Grid } from "@mui/material";
import { CustomSelect } from "@src/components/form/custom-select";
import { TypeInput } from "@src/components/form/type-input";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { TemplateForm } from "@src/components/form/template-sub-form";
import { useFormContext } from "react-hook-form";
import { stringOrTemplateChoicesModel } from "@containers/private/service-definition/form/constant";
interface IProps {
  value: string;
  onChange: (d: string) => void;
}

export const RenderersImage = ({ value, onChange }: IProps) => {
  const { t } = useTranslation();
  const {
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext();
  const isFormVisible = value === "true";
  const handleChange = (value: string) => {
    onChange(value);
    if (value === "false") {
      setValue("renderers_image", "");
      clearErrors("renderers_image");
    } else {
      clearErrors("renderers_image");
    }
  };

  return (
    <Fragment>
      <Grid
        container
        spacing={3}
        rowSpacing={3}
        data-testid="renderers-image-container"
      >
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} rowSpacing={3} direction="column">
            <Grid item>
              <CustomSelect
                options={stringOrTemplateChoicesModel}
                onChange={(v: string) => handleChange(v)}
                value={value}
                label={t("page__service__choose renderers image model")}
              />
            </Grid>
            {isFormVisible ? (
              <TemplateForm
                disabledEnv={!!errors?.renderers_image?.env?.length}
                disabledResp={
                  !!errors?.renderers_image?.rest_resp_template?.length
                }
                type="renderers_image"
              />
            ) : (
              <Grid item>
                <TypeInput
                  label={t("page__service__renderers image")}
                  name="renderers_image"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};
