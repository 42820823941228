import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Checkbox,
} from "@mui/material";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { TableActionMenu } from "@components/entity_table/table-action-menu";
import { IEntityModel } from "@src/models/entity-model.model";
import { ITableBulkAction } from "@components/type/tabel-bulk-action-type";
import { Loading } from "@components/loading";
import { Error } from "@components/error";
import { Empty } from "@components/empty";
import { BulkActionToolbar } from "@components/entity_table/bulk-action-toolbar";
export interface IColumn<T> {
  title: string;
  key: string;
  align?: "left" | "right" | "center";
  render?: (col: IColumn<T>, record: T) => React.ReactNode;
}

export interface ITableAction<T> {
  icon: JSX.Element;
  title: string;
  action: (data: T) => void;
  isDisabled?: (data: T) => boolean;
}
interface ITableProps<T> {
  onRowClick?: (data: T) => void;
  error: boolean;
  actions?: Array<ITableAction<T>>;
  loading: boolean;
  dataList: Array<T>;
  tableColumns: Array<IColumn<T>>;
  selectedItems?: Array<T>;
  onSelectionChange?: (data: Array<T>) => void;
  bulkActions?: Array<ITableBulkAction>;
  bulkeSelectedMessage: string;
  showCheckbox?: boolean;
}
export function EntityTable<T extends IEntityModel>(props: ITableProps<T>) {
  const { t } = useTranslation();
  const {
    onRowClick,
    error = false,
    dataList = [],
    tableColumns = [],
    loading = false,
    actions = [],
    bulkActions = [],
    selectedItems = [],
    bulkeSelectedMessage,
    onSelectionChange,
    showCheckbox = false,
  } = props;
  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, data: T) => {
    if (event.target.checked === false) {
      const selectedModifiedData = selectedItems.filter((item: T) => {
        return item.id !== data.id;
      });
      onSelectionChange && onSelectionChange(selectedModifiedData);
    } else {
      const selectedModifiedData = [...selectedItems, data];
      onSelectionChange && onSelectionChange(selectedModifiedData);
    }
  };
  const getLoading = () => {
    return (
      <TableRow>
        <TableCell colSpan={tableColumns.length + 1}>
          <Loading />
        </TableCell>
      </TableRow>
    );
  };
  const onClickRow = (data: T) => {
    onRowClick && onRowClick(data);
  };
  const getTableBodyRows = () => {
    type DataKeyType = keyof T;
    if (error) {
      return (
        <TableRow>
          <TableCell colSpan={tableColumns.length + 1}>
            <Error />
          </TableCell>
        </TableRow>
      );
    }
    if (dataList.length > 0 && tableColumns.length > 0) {
      return dataList.map((data: T) => {
        const isItemSelected =
          showCheckbox &&
          selectedItems.findIndex((s: T) => s.id === data.id) !== -1;
        return (
          <TableRow
            onClick={() => onClickRow(data)}
            hover
            key={`data_table_${data.id}`}
            tabIndex={-1}
            selected={isItemSelected}
            aria-checked={isItemSelected}
          >
            {showCheckbox && (
              <TableCell>
                <Checkbox
                  checked={isItemSelected}
                  onChange={(event) => {
                    handleClick(event, data);
                  }}
                />
              </TableCell>
            )}
            {tableColumns?.map((column: IColumn<T>) => {
              const colValueInString = String(data[column.key as DataKeyType]);
              return (
                <Fragment key={`table_crud_column_${column.key}_${data.id}`}>
                  <TableCell
                    className="body-table-cell"
                    align={`${column.align ? column.align : "left"}`}
                  >
                    {column.render
                      ? column.render(column, data)
                      : colValueInString}
                  </TableCell>
                </Fragment>
              );
            })}
            <Fragment>
              <TableCell>
                {actions && actions.length > 0 ? (
                  <TableActionMenu<T> data={data} actionList={actions} />
                ) : (
                  <></>
                )}
              </TableCell>
            </Fragment>
          </TableRow>
        );
      });
    }

    return (
      <TableRow>
        <TableCell colSpan={tableColumns.length + 1}>
          <Empty />
        </TableCell>
      </TableRow>
    );
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      onSelectionChange && onSelectionChange(dataList);
      return;
    }
    onSelectionChange && onSelectionChange([]);
  };
  return (
    <Fragment>
      {selectedItems.length !== 0 && (
        <BulkActionToolbar
          onClose={() => onSelectionChange && onSelectionChange([])}
          bulkActions={bulkActions}
          message={bulkeSelectedMessage}
        />
      )}
      <TableContainer className="table-container">
        <Table>
          <TableHead>
            <TableRow className="table-header">
              {showCheckbox && (
                <TableCell>
                  <Checkbox
                    className={selectedItems.length ? "" : "tabel-checkbox"}
                    indeterminate={
                      selectedItems.length > 0 &&
                      selectedItems.length < dataList.length
                    }
                    checked={
                      dataList.length > 0 &&
                      selectedItems.length === dataList.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}
              {tableColumns.map((column: IColumn<T>) => (
                <TableCell
                  align="left"
                  key={`${column.key}`}
                  className="table-header-cell"
                >
                  {column.title}
                </TableCell>
              ))}
              {actions && actions.length > 0 ? (
                <TableCell className="table-header-cell" key={"action"}>
                  {t("action")}
                </TableCell>
              ) : (
                <TableCell />
              )}
            </TableRow>
          </TableHead>
          <TableBody>{loading ? getLoading() : getTableBodyRows()}</TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
}
