import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  AccordionProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

interface AccordionComponentProps extends AccordionProps {
  id: string;
  index: number;
  header: string;
  onDelete: (index: number) => void;
  children: NonNullable<React.ReactNode>;
  className?: string;
}

export const AccordionComponent: React.FC<AccordionComponentProps> = ({
  id,
  index,
  header,
  onDelete,
  children,
  expanded = true,
  className = "",
  ...accordionProps
}) => {
  return (
    <Accordion
      defaultExpanded={expanded}
      className={`accordion ${className}`}
      {...accordionProps}
    >
      <AccordionSummary
        className="accordion-summary"
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}${index}d-content`}
        id={`${id}${index}d-header`}
        data-testid={`${id}${index}`}
      >
        <div className="content">
          <Typography variant="h6">{header}</Typography>
          <IconButton
            className="delete-icon"
            color="inherit"
            onClick={() => onDelete(index)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="accordion-detail">{children}</div>
      </AccordionDetails>
    </Accordion>
  );
};
