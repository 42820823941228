import { Outlet } from "react-router-dom";
import { PublicFooter } from "@components/layout/public-layout/public-footer/public-footer";
import { PublicHeader } from "@components/layout/public-layout/public-header/public-header";
import React from "react";

export const PublicLayout: React.FC<{}> = () => {
  return (
    <div>
      <div className="publicLayout">
        <PublicHeader />
        <div className="mainBody">
          <Outlet />
        </div>
        <PublicFooter />
      </div>
    </div>
  );
};
