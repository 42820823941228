import { Box, Container, Grid } from "@mui/material";
import { EPUBLICROUTE } from "@src/constants/enum/public-route.enum";
import { Link } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

export const PublicFooter: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <footer className="publicFooter">
      <Box
        bgcolor="primary.main"
        color="white"
        px={{ xs: 3, sm: 10 }}
        py={{ xs: 5, sm: 10 }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Box borderBottom={1}>{t("Help")}</Box>
              <Box>
                <Link to={EPUBLICROUTE.CONTACTUS} color="inherit">
                  {t("Contact us")}
                </Link>
              </Box>
              <Box>
                <Link to={EPUBLICROUTE.HOME} color="inherit">
                  {t("Support")}
                </Link>
              </Box>
              <Box>
                <Link to={EPUBLICROUTE.HOME} color="inherit">
                  {t("Privacy")}
                </Link>
              </Box>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Box borderBottom={1}>{t("Account")}</Box>
              <Box>
                <Link to={EPUBLICROUTE.HOME} color="inherit">
                  {t("login")}
                </Link>
              </Box>
              <Box>
                <Link to={EPUBLICROUTE.HOME} color="inherit">
                  {t("Register")}
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  );
};
