import React, { useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

interface IProps {
  label?: string;
  tags: Array<string>;
  onChange?: (tags: Array<string>) => void;
  name?: string;
}
export const TagChipList: React.FC<IProps> = ({
  tags,
  onChange,
  name,
  label,
}) => {
  const [selected, setSelected] = useState(tags);
  const { t } = useTranslation();

  const changeTags = (tags: Array<string>) => {
    setSelected(tags);
    onChange && onChange(tags);
  };
  useEffect(() => {
    setSelected(tags);
  }, [tags]);
  const tag_validation = (tag: string, tags: string[]) =>
    tags.length < 10 && tag.length > 0 && tag.length < 32;
  return (
    <>
      <Typography variant="subtitle2" color="primary">
        {t("message__you have to press inter to each", { label: label })}
      </Typography>
      <TagsInput
        value={selected}
        onChange={changeTags}
        name={name}
        placeHolder={label || t("enter Tags")!}
        beforeAddValidate={tag_validation}
      />
    </>
  );
};
