import { useFormContext, Controller } from "react-hook-form";
import { OutlinedTextFieldProps, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IProps extends Omit<OutlinedTextFieldProps, "variant"> {
  name: string;
  label: string;
  type?: string;
}

export function TypeInput(props: IProps) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { name, label, type = "text" } = props;
  return (
    <Controller
      {...props}
      defaultValue=""
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          variant="outlined"
          {...field}
          label={t(label)}
          placeholder={t(label)!}
          type={type}
          value={field.value || ""}
          fullWidth
          aria-invalid={!!error}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
}
