import { Button, CardContent, Grid, Stack } from "@mui/material";
import * as Yup from "yup";
import React, { Fragment } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { IServiceDefinitionFilter } from "@src/models/service-definition.model";
import { TypeInput } from "@src/components/form/type-input";

interface IProps {
  handelQuery: (data: IServiceDefinitionFilter) => void;
  defaultValue: IServiceDefinitionFilter;
}

export const Filter: React.FC<IProps> = ({ handelQuery, defaultValue }) => {
  const schema = Yup.object().shape({});
  const methods = useForm<IServiceDefinitionFilter>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: defaultValue,
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;
  const { t } = useTranslation();

  const isValid = Object.keys(errors).length == 0;
  const onSubmit = (data: IServiceDefinitionFilter) => {
    handelQuery({
      ...defaultValue,
      ...data,
    });
  };
  return (
    <Fragment>
      <Stack
        data-testid="service-filter-container"
        className="filter-form-wrapper"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <CardContent>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                direction="column"
              >
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <TypeInput label={t("name")} name="name" />
                  </Grid>
                  <Grid item xs={6}>
                    <TypeInput label={t("title")} name="title" />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="end"
                    alignItems="center"
                  >
                    <Button
                      className="button-margin-submit"
                      data-testid="submit_button"
                      disabled={!isValid}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {t("filter")}
                    </Button>

                    <Button
                      className="button-margin"
                      data-testid="reset_button"
                      variant="contained"
                      disabled={!isValid}
                      color="primary"
                      onClick={() => {
                        const resetValue = {
                          name: null,
                          title: null,
                        };
                        reset(resetValue);
                        handelQuery({ ...defaultValue, ...resetValue });
                      }}
                    >
                      {t("reset")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </CardContent>
      </Stack>
    </Fragment>
  );
};
