import { useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";
import { createContext, useContext, ReactNode } from "react";

import { useNavigate } from "react-router-dom";

type NavContextProps = (data: string) => void;
export const NavContext = createContext<NavContextProps | undefined>(undefined);

export const useNavContext = (): NavContextProps => {
  const context = useContext(NavContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppContextProvider");
  }
  return context;
};

export const NavContextProvider: React.FC<{
  children: ReactNode;
  value?: NavContextProps | undefined;
}> = ({ children, value: externalNavigateToPath }) => {
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject.name
  );

  const navigate = useNavigate();

  const navigateToPath = (data: string) => {
    const urlParams = new URLSearchParams(data);
    const existingSelectedProject = urlParams.get("selectedProject");
    if (!existingSelectedProject) {
      navigate(`${data}?selectedProject=${selectedProject}`);
    } else {
      navigate(data);
    }
  };

  const contextValue: NavContextProps =
    externalNavigateToPath || navigateToPath;

  return (
    <NavContext.Provider value={contextValue}>{children}</NavContext.Provider>
  );
};
