import { Grid } from "@mui/material";
import { CustomSelect } from "@src/components/form/custom-select";
import { Fragment, useState } from "react";
import { stringOrTemplateChoicesModel } from "@containers/private/service-definition/form/constant";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { TypeInput } from "@src/components/form/type-input";
import { TemplateForm } from "@components/form/template-sub-form";
interface IProps {
  index: number;
}
export const VariablesValueTemplate = ({ index }: IProps) => {
  const [isVariablesValueForm, setIsVariablesValueForm] = useState<
    Array<{ index: number; value: string }>
  >([{ index: 0, value: "false" }]);
  const {
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  const isFormVisible = isVariablesValueForm.find(
    (v) => v.index === index
  )?.value;

  const handleChange = (index: number, value: string) => {
    const updatedVariables = [...isVariablesValueForm];
    const variable = updatedVariables.find((v) => v.index === index);

    if (variable) {
      variable.value = value;
    } else {
      updatedVariables.push({ index, value });
    }
    setIsVariablesValueForm(updatedVariables);

    if (value === "false") {
      setValue(`variables[${index}].value_template`, "");
      clearErrors(`variables[${index}].value_template`);
    } else {
      clearErrors(`variables[${index}].value_template`);
    }
  };
  return (
    <Fragment>
      <Grid item data-testid="template-form-variable">
        <Grid container spacing={2} rowSpacing={3} direction="column">
          <Grid item>
            <CustomSelect
              options={stringOrTemplateChoicesModel}
              onChange={(value: string) => handleChange(index, value)}
              name={""}
              value={
                isVariablesValueForm.find((v) => v.index === index)?.value ||
                "false"
              }
              label={t("page__service__choose a variable value model")}
            />
          </Grid>
          {isFormVisible === "true" ? (
            <TemplateForm
              disabledResp={
                !!errors?.variables?.[index]?.value_template?.rest_resp_template
                  ?.length
              }
              disabledEnv={
                !!errors?.variables?.[index]?.value_template?.env?.length
              }
              type={`variables[${index}].value_template`}
            />
          ) : (
            <Grid item>
              <TypeInput
                name={`variables.${index}.value_template`}
                label={t("page__service__valueTemplate")}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
