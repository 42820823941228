import { Grid } from "@mui/material";
import { TypeInput } from "@src/components/form/type-input";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { FormSelect } from "@src/components/form/form-select";
import { typeOptions } from "@containers/private/service-definition/form/constant";
import { EnvSubForm } from "@components/form/env-sub-form";
import { RespSubForm } from "@components/form/resp-sub-form";

interface IProps {
  type: string;
  disabledResp: boolean;
  disabledEnv: boolean;
}
export const TemplateForm = ({ type, disabledResp, disabledEnv }: IProps) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Grid item xs={12} md={6} data-testid={`${type}-template-form`}>
        <Grid container spacing={2} rowSpacing={3} direction="column">
          <Grid item>
            <TypeInput label={t("template")} name={`${type}.template`} />
          </Grid>
          <Grid item>
            <FormSelect
              label={t("type")}
              name={`${type}.type`}
              options={typeOptions}
            />
          </Grid>
        </Grid>
        <EnvSubForm type={type} disabled={disabledEnv} />
        <RespSubForm type={type} disabled={disabledResp} />
      </Grid>
    </Fragment>
  );
};
