import { Button, Divider, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { TypeInput } from "@src/components/form/type-input";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TitleWithHelpLink } from "@src/components/form/title-with-help-link";
import { emptyLabel } from "@src/constants/empty-label";

export const LabelArray = () => {
  const { t } = useTranslation();
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();
  const {
    fields: labels,
    append: appendLabel,
    remove: removeLabel,
  } = useFieldArray({
    control,
    name: "labels",
  });
  useEffect(() => {
    trigger("labels");
  }, [labels]);
  return (
    <Grid item className="label-wrapper" data-testid="labels-array">
      <TitleWithHelpLink title="labels" />
      {labels.map((label, index) => {
        return (
          <Grid
            key={label.id}
            container
            spacing={2}
            alignItems={"center"}
            className="label-input"
          >
            <Grid item xs={10} md={5}>
              <TypeInput name={`labels.${index}.key`} label={t("key")} />
            </Grid>
            <Grid item xs={10} md={5}>
              <TypeInput name={`labels.${index}.value`} label={t("value")} />
            </Grid>
            <Grid item xs={2} md={2}>
              <IconButton color="inherit" onClick={() => removeLabel(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Divider className="divider" />
          </Grid>
        );
      })}
      <Button
        variant="contained"
        className="label-button"
        disabled={!!errors?.labels?.length}
        onClick={() => {
          appendLabel(emptyLabel);
        }}
      >
        {t("+ Add Labels")}
      </Button>
      <Divider className="divider" />
    </Grid>
  );
};
