import { InputAdornment, OutlinedInput } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { delayCallback } from "@src/helpers/utils";
interface IProps {
  customPlaceHolder?: string;
  onChange: (data: string) => void;
}
export const sleepTime = 300;
export const SearchBox: React.FC<IProps> = ({
  customPlaceHolder,
  onChange,
}) => {
  const [value, setValue] = useState<string | null>("");
  const { t } = useTranslation();

  let timeoutId: ReturnType<typeof setTimeout>;
  function handleKeyUp() {
    clearTimeout(timeoutId);
    timeoutId = delayCallback(() => onChange(value!), sleepTime);
  }
  const keyUpHandler = () => {
    clearTimeout(timeoutId);
  };
  return (
    <OutlinedInput
      data-testid="search-box"
      value={value}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
      }}
      onKeyUp={() => {
        handleKeyUp();
      }}
      onKeyDown={() => keyUpHandler()}
      placeholder={customPlaceHolder ? t(customPlaceHolder)! : t("search")!}
      startAdornment={
        <InputAdornment position="start" className="searchIcon">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};
