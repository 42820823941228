import { Button, Grid } from "@mui/material";
import { useEffect } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PortFormContent } from "./port-form";
import { TitleWithHelpLink } from "@src/components/form/title-with-help-link";
import { AccordionComponent } from "@src/components/accordion/accordion";
import { emptyPort } from "../../constant/empty-port";
interface IProps {
  type: "ports_remote_services" | "ports_listening_ports";
  title: "ports remote services" | "ports listening ports";
}
export const PortCard = ({ type, title }: IProps) => {
  const { t } = useTranslation();
  const {
    trigger,
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${type}`,
  });
  useEffect(() => {
    trigger(`${type}`);
  }, [fields]);
  const typeName = useWatch({
    name: `${type}`,
    control,
  });
  function getAddButtonDisabled(): boolean {
    let disabled = false;

    switch (type) {
      case "ports_listening_ports":
        disabled = !!errors?.ports_listening_ports?.length;
        break;
      case "ports_remote_services":
        disabled = !!errors?.ports_remote_services?.length;
        break;
      default:
        disabled = false;
    }
    return disabled;
  }
  return (
    <div className="port-wrapper" data-testid="port-wrapper">
      <Grid item className="label-wrapper">
        <TitleWithHelpLink
          title={`${t(title)}`}
          error={!!errors[type]?.length}
        />
      </Grid>
      {fields.map((field, index) => {
        return (
          <AccordionComponent
            key={`${type}__${field.id}`}
            id={`${field}`}
            index={index}
            header={`${t(title)}: ${typeName?.[index]?.name || "(__)"}`}
            onDelete={remove}
          >
            <Grid
              container
              spacing={2}
              alignItems={"center"}
              className="label-input"
              data-testid={type}
            >
              <PortFormContent type={type} index={index} />
            </Grid>
          </AccordionComponent>
        );
      })}
      <Button
        className="add-button-in-accordion"
        fullWidth
        disabled={getAddButtonDisabled()}
        onClick={() => {
          append(emptyPort);
        }}
      >
        {t("page__service__+ Add ports", { type: title })}
      </Button>
    </div>
  );
};
