import { AboutUs } from "@containers/public/about-us";
import { ContactUs } from "@containers/public/contact-us";
import { EPUBLICROUTE } from "@constants/enum/public-route.enum";
import { Faq } from "@containers/public/app-faq";
import { Home } from "@containers/public/home";
import { Sample } from "@src/containers/public/component-sample";

interface IPublicRoute {
  path: EPUBLICROUTE;
  element: JSX.Element;
}

export const publicRoutes: IPublicRoute[] = [
  { path: EPUBLICROUTE.HOME, element: <Home /> },
  { path: EPUBLICROUTE.ABOUTUS, element: <AboutUs /> },
  { path: EPUBLICROUTE.CONTACTUS, element: <ContactUs /> },
  { path: EPUBLICROUTE.FAQ, element: <Faq /> },
  {
    path: EPUBLICROUTE.SAMPLE,
    element: <Sample />,
  },
];
