import { ServiceDefinitionRepository } from "@src/services/service-definition.repository";
import { colDef } from "@src/helpers/utils";
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  IColumn,
  ITableAction,
} from "@src/components/entity_table/entity_table";
import { ITableBulkAction } from "@src/components/type/tabel-bulk-action-type";
import { GenericList } from "@src/components/generic-list/generic-list";
import {
  IServiceDefinition,
  IServiceDefinitionFilter,
} from "@src/models/service-definition.model";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Filter } from "@src/containers/private/service-definition/filter";
import { HeaderList } from "@src/containers/private/service-definition/header";
import { EntityNotFoundException } from "@src/services/repository";
import { ConfirmModal } from "@src/components/confirm_modal";
import { EMODAL_ACTION } from "@src/constants/enum/confirm-modal-action";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
export interface IProps {
  serviceRepo?: ServiceDefinitionRepository;
}

export const ServiceDefinitionList: React.FC<IProps> = ({ serviceRepo }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [bulkSelected, setBulkSelected] = useState<Array<IServiceDefinition>>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [repo, setRepo] = useState<ServiceDefinitionRepository>(
    serviceRepo || new ServiceDefinitionRepository()
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedDeleteData, setSelectedDeleteData] =
    useState<IServiceDefinition>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const fields: Array<keyof IServiceDefinitionFilter> = [
    "name",
    "title",
    "since",
    "before",
    "page_size",
  ];
  const query: IServiceDefinitionFilter = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields.forEach((x) => ((query as any)[x] = searchParams.get(x)));

  useEffect(() => {
    setRepo(serviceRepo || repo);
  }, [serviceRepo != null]);

  const bulkAction: Array<ITableBulkAction> = [
    {
      icon: <DeleteIcon />,
      title: t("delete"),
      isDisabled: false,
      callBack: () => {
        return;
      },
    },
  ];
  const columns: IColumn<IServiceDefinition>[] = [
    colDef("name", t("name")),
    colDef("title", t("title")),
    colDef("description", t("description"), (_, y) =>
      y.description ? y.description : ""
    ),
    colDef("configs", t("page__service__count of config"), (_, y) =>
      y.configs ? y.configs.length : 0
    ),
    colDef("volumes", t("page__service__volumes"), (_, y) =>
      y.volumes ? y.volumes.length : 0
    ),
  ];
  const actionList: Array<ITableAction<IServiceDefinition>> = [
    {
      icon: <DeleteIcon />,
      title: t("delete"),
      action: (data: IServiceDefinition) => {
        setShowDeleteModal(true);
        setSelectedDeleteData(data);
      },
    },
    {
      icon: <ModeEditIcon />,
      title: t("edit"),
      action: (data: IServiceDefinition) => {
        navigate(`${EPRIVATEROUTE.SERVICE_DEFINITIONS}/${data.id}`);
      },
    },
  ];
  const deleteService = async () => {
    setLoading(true);
    setError(false);
    try {
      const res = await repo.delete(selectedDeleteData!);
      if (res) {
        toast.success(
          t("message__your request has been successfully completed")
        );
        setRefresh(!refresh);
      }
    } catch (error) {
      if (error instanceof EntityNotFoundException) {
        toast.success(
          t("message__your request has been successfully completed")
        );
        return;
      }
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };
  const getActionModal = (data: EMODAL_ACTION) => {
    switch (data) {
      case EMODAL_ACTION.YES:
        deleteService();
        break;
      default:
        setShowDeleteModal(false);
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>{t("page__type__services")}</title>
      </Helmet>
      <ToastContainer />
      <GenericList
        stateError={[error, setError]}
        stateLoading={[loading, setLoading]}
        stateSelectedItems={[bulkSelected, setBulkSelected]}
        bulkActionList={bulkAction}
        bulkSelectedMsgTemplate={
          bulkSelected.length > 1
            ? t("page__service__servicesSelected", {
                num: bulkSelected.length,
              })
            : t("page__service__serviceSelected", {
                num: bulkSelected.length,
              })
        }
        columns={columns}
        HeaderComponent={HeaderList}
        FilterComponent={Filter}
        initQuery={query}
        repo={repo}
        rowActionList={actionList}
      />
      <ConfirmModal
        show={showDeleteModal}
        title={t("delete name", {
          name: selectedDeleteData?.name,
        })}
        text={t("are you sure to delete", {
          name: selectedDeleteData?.name,
        })}
        callBack={getActionModal}
        yes={"delete"}
        no={"cancel"}
      />
    </Fragment>
  );
};
