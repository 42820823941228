import {
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";

interface IProps {
  title: string;
  link?: string;
  error?: boolean;
}
export const TitleWithHelpLink = ({
  title,
  link = "",
  error = false,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Grid item data-testid={title}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="start"
      >
        <Typography>{t(title)}</Typography>

        <FormHelperText error={error} data-testid={`error_${title}`}>
          {error ? t("message__please fix the error") : ""}
        </FormHelperText>

        <Link to={link}>
          <IconButton color="info">
            <HelpIcon />
          </IconButton>
        </Link>
      </Stack>
    </Grid>
  );
};
