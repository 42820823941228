import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ITableBulkAction } from "@components/type/tabel-bulk-action-type";

interface IProps {
  bulkActions: Array<ITableBulkAction>;
  message: string;
  onClose: () => void;
}

export const BulkActionToolbar: React.FC<IProps> = ({
  bulkActions,
  message,
  onClose,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={4}
      className="bulk-action-header"
    >
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <Button
          className="border-radius"
          data-testid="select-bulk-action-button-close"
          variant="text"
          startIcon={<CloseIcon />}
          onClick={onClose}
        />

        <Typography variant="h5" data-testid="selecte-number-bulk-action">
          {message}
        </Typography>
      </Stack>
      {bulkActions.map((action: ITableBulkAction, index: number) => {
        return (
          <Button
            disabled={action.isDisabled}
            key={`table-bulk-action-${index}`}
            data-testid={`select-bulk-action-button-${action.title}`}
            variant="text"
            startIcon={action.icon}
            onClick={action.callBack}
          >
            {action.title}
          </Button>
        );
      })}
    </Stack>
  );
};
