import { Button, Divider, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { TypeInput } from "@src/components/form/type-input";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { emptyResp } from "@src/constants/empty-resp";
import { TitleWithHelpLink } from "@src/components/form/title-with-help-link";
import { FormChipList } from "@src/components/form/form-chip-list";
interface IProps {
  type: string;
  disabled: boolean;
}
export const RespSubForm = ({ type, disabled }: IProps) => {
  const { t } = useTranslation();
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();
  const {
    fields: respFields,
    append: appendResp,
    remove: removeResp,
  } = useFieldArray({
    control,
    name: `${type}.rest_resp_template`,
  });
  useEffect(() => {
    trigger(`${type}.rest_resp_template`);
  }, [respFields]);
  return (
    <Grid
      container
      spacing={2}
      rowSpacing={3}
      direction="column"
      data-testid={`${type}_rest_resp_template`}
    >
      <TitleWithHelpLink
        title="page__service__rest_resp_template"
        error={!!errors.renderers_image?.rest_resp_template?.length}
      />
      <Grid item xs={12} md={6}>
        {respFields.map((field, index) => {
          return (
            <Grid
              key={field.id}
              container
              spacing={2}
              rowSpacing={1}
              direction="column"
            >
              <Grid item>
                <TypeInput
                  name={`${type}.rest_resp_template.${index}.template`}
                  label={t("page__service__template")}
                />
              </Grid>
              <Grid item>
                <FormChipList
                  name={`${type}.rest_resp_template.${index}.code`}
                  label={t("page__service__statusCode")!}
                />
                <IconButton color="inherit" onClick={() => removeResp(index)}>
                  <DeleteIcon />
                </IconButton>
                <Divider className="divider" />
              </Grid>
            </Grid>
          );
        })}
        <Button
          data-testid={`${type}_rest_resp_template`}
          variant="contained"
          className="label-button"
          disabled={disabled}
          onClick={() => {
            appendResp(emptyResp);
          }}
        >
          {t("page__service__+ Add rest_resp_template")}
        </Button>
      </Grid>
    </Grid>
  );
};
