import { Button, Grid } from "@mui/material";
import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { TemplateForm } from "@src/components/form/template-sub-form";
import { TitleWithHelpLink } from "@src/components/form/title-with-help-link";

interface IProps {
  value: boolean;
  onChange: (d: boolean) => void;
}
export const RenderersEnvCard = ({ value, onChange }: IProps) => {
  const { t } = useTranslation();
  const {
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext();
  const handleClick = () => {
    onChange(!value);
  };
  const deleteRenderEnv = () => {
    setValue("renderers_env_file", null);
    clearErrors("renderers_env_file");
    onChange(!value);
  };

  return (
    <Fragment>
      <Grid item xs={12} md={6} data-testid="renderers-env-form">
        <Grid container spacing={2} rowSpacing={3} direction="column">
          <TitleWithHelpLink
            title="page__service__renderers env file"
            error={!!errors.renderers_env_file}
          />
          <Grid item>
            {!value ? (
              <Button
                variant="contained"
                className="label-button"
                onClick={handleClick}
              >
                {t("page__service__+ Add renderers env")}
              </Button>
            ) : (
              <DeleteIcon onClick={deleteRenderEnv} />
            )}
          </Grid>
          {value && (
            <Grid item>
              <TemplateForm
                disabledEnv={!!errors.renderers_env_file?.env?.length}
                disabledResp={
                  !!errors.renderers_env_file?.rest_resp_template?.length
                }
                type="renderers_env_file"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};
