import { Repository } from "@src/services/repository";
import { IProject, IProjectFilters } from "@src/models/project.model";

export class ProjectRepository extends Repository<
  IProject,
  IProject,
  IProject,
  IProject,
  IProjectFilters
> {
  constructor() {
    super("/v1.0/projects");
  }
}
