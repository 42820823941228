import { Grid } from "@mui/material";
import { TypeInput } from "@src/components/form/type-input";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ConditionForm } from "@src/containers/private/service-definition/form/condition-form";
import { FunctionalityForm } from "@src/containers/private/service-definition/form/functionality-form";
import { useFormContext } from "react-hook-form";
import { TemplateForm } from "@src/components/form/template-sub-form";
import { TitleWithHelpLink } from "@src/components/form/title-with-help-link";

interface IProps {
  type: "ports_remote_services" | "ports_listening_ports";
  index: number;
}
export const PortFormContent = ({ type, index }: IProps) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();
  function getRespOrEnvDisabledState(
    field: "env" | "rest_resp_template"
  ): boolean {
    let disabled = false;

    switch (type) {
      case "ports_listening_ports":
        disabled =
          !!errors?.ports_listening_ports?.[index]?.network_stack_template?.[
            field
          ]?.length;
        break;
      case "ports_remote_services":
        disabled =
          !!errors?.ports_remote_services?.[index]?.network_stack_template?.[
            field
          ]?.length;
        break;
      default:
        disabled = false;
    }
    return disabled;
  }
  function getDisabledState(field: "functionalities" | "condition"): boolean {
    let disabled = false;

    switch (type) {
      case "ports_listening_ports":
        disabled = !!errors?.ports_listening_ports?.[index]?.[field]?.length;
        break;
      case "ports_remote_services":
        disabled = !!errors?.ports_remote_services?.[index]?.[field]?.length;
        break;
      default:
        disabled = false;
    }
    return disabled;
  }
  return (
    <Fragment>
      <Grid item xs={12} md={7}>
        <Grid container spacing={2} rowSpacing={3} direction="column">
          <Grid item>
            <TypeInput label={t("name")} name={`${type}.${index}.name`} />
          </Grid>
          <Grid item>
            <TypeInput
              label={t("page__service__network stack")}
              name={`${type}.${index}.network_stack`}
            />
          </Grid>
          <Grid item>
            <TypeInput
              label={t("page__service__connection count min")}
              name={`${type}.${index}.connection_count.min`}
              type="number"
            />
          </Grid>
          <Grid item>
            <TypeInput
              label={t("page__service__connection count max")}
              name={`${type}.${index}.connection_count.max`}
              type="number"
            />
          </Grid>
          <Grid item>
            <TitleWithHelpLink
              title="page__service__network stack template"
              error={!!errors[type]?.index?.network_stack_template}
            />
          </Grid>
          <TemplateForm
            disabledEnv={getRespOrEnvDisabledState("env")}
            disabledResp={getRespOrEnvDisabledState("rest_resp_template")}
            type={`${type}[${index}].network_stack_template`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2} rowSpacing={3} direction="column">
          <ConditionForm
            type={`${type}[${index}]`}
            disabled={getDisabledState("condition")}
          />
          <FunctionalityForm
            type={`${type}[${index}]`}
            disabled={getDisabledState("functionalities")}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};
