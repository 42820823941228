import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
import {
  ConflictException,
  EntityNotFoundException,
  IEntityFieldError,
  InvalidEntityFields,
} from "@src/services/repository";
import { FORM_TOAST_TIMEOUT } from "@src/constants/form";
import { delayCallback } from "@src/helpers/utils";
import { IReduxState } from "@src/redux/root-reducer";
import { useAppSelector } from "@src/redux/hooks";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { Loading } from "@src/components/loading";
import { useNavContext } from "@src/app/navigation-context";
import { ServiceDefinitionRepository } from "@src/services/service-definition.repository";
import { IServiceDefinition } from "@src/models/service-definition.model";
import { SLServiceDefinitionForm } from "@containers/private/service-definition/form/stateless-form";
import { IServiceFormData } from "../model/form.model";
import { IVariable } from "@src/models/minimal-with-config-variable.model";
export interface IProps {
  serviceRepo?: ServiceDefinitionRepository;
}
export const CreateServiceDefinition: React.FC<IProps> = ({ serviceRepo }) => {
  const navContext = useNavContext();
  const { t } = useTranslation();
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );
  const params = useParams();
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [model, setModel] = useState<IServiceDefinition | null>(null);
  const [errorList, setErrorList] = useState<Array<
    IEntityFieldError<IServiceDefinition>
  > | null>(null);
  const [repoService, setRepoService] = useState<ServiceDefinitionRepository>(
    serviceRepo || new ServiceDefinitionRepository()
  );
  useEffect(() => {
    if (params.service_definitions_id) {
      setEditMode(true);
      getServiceById(params.service_definitions_id);
    }
  }, [params.service_definitions_id]);

  useEffect(() => {
    setRepoService(repoService || serviceRepo);
  }, [serviceRepo != null]);
  const processServerError = (
    errors: unknown,
    toastUnexpectedError = true,
    returnToList = false
  ) => {
    if (!navigator.onLine) {
      toast.error(t("error__network_error"));
      return;
    }
    if (errors instanceof InvalidEntityFields) {
      setErrorList(errors.detail);
      return;
    }
    if (errors instanceof ConflictException) {
      setErrorList([
        {
          loc: ["body", "name"],
          msg: t(
            "error__service with this name exists, please choose another name"
          ),
          type: "conflict",
        },
      ]);
      return;
    }
    if (errors instanceof EntityNotFoundException) {
      setErrorList(errors.detail);
      return;
    }

    toastUnexpectedError &&
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
    returnToList &&
      delayCallback(
        () => navContext(EPRIVATEROUTE.SERVICE_DEFINITIONS),
        FORM_TOAST_TIMEOUT
      );
  };

  const CreateServiceDefinition = async (formData: IServiceDefinition) => {
    setLoading(true);
    try {
      await repoService.create(formData);
      navContext(EPRIVATEROUTE.SERVICE_DEFINITIONS);
    } catch (errors) {
      processServerError(errors);
    } finally {
      setLoading(false);
    }
  };
  const getServiceById = async (id: string) => {
    setFetchLoading(true);
    try {
      const { data: service } = await repoService.getById(id);
      setModel(service);
    } catch (errors) {
      processServerError(errors, true, true);
    } finally {
      setFetchLoading(false);
    }
  };

  const editService = async (formValues: IServiceDefinition) => {
    setLoading(true);
    const newData = { ...model, ...formValues };
    try {
      await repoService.update(newData);
      navContext(EPRIVATEROUTE.SERVICE_DEFINITIONS);
    } catch (errors) {
      processServerError(errors, true, true);
    } finally {
      setLoading(false);
    }
  };
  const transformData = (formValues: IServiceFormData): IServiceDefinition => {
    delete formValues.labels;
    const formData = {
      ...formValues,
      project_id: selectedProject.id,
      labels: {},
    };
    if (formData.variables) {
      const transformedVariables = formData.variables.map((variable) => {
        if (typeof variable.value_template === "string") {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { value_template, ...rest } = variable;
          return {
            ...rest,
            value: variable.value_template,
            value_template: null,
          };
        }
        return {
          ...variable,
          value: null,
        };
      });

      return {
        ...formData,
        variables: transformedVariables as Array<IVariable>,
      };
    }
    return formData as IServiceDefinition;
  };
  const onSubmit = async (formValues: IServiceFormData) => {
    const formData = transformData(formValues);
    editMode ? editService(formData) : CreateServiceDefinition(formData);
  };
  return (
    <Fragment>
      <Helmet>
        <title>{t("create")}</title>
      </Helmet>
      <ToastContainer autoClose={FORM_TOAST_TIMEOUT} />
      {fetchLoading ? (
        <Loading />
      ) : (
        <div
          className="create-service-wrapper"
          data-testid="create-service-wrapper"
        >
          <SLServiceDefinitionForm
            editMode={editMode}
            errorList={errorList}
            loading={loading}
            onSubmit={onSubmit}
            model={model}
          />
        </div>
      )}
    </Fragment>
  );
};
