import {
  MAX_LABELS_COUNT,
  MAX_LABEL_CHARACTERS,
  MIN_LABEL_CHARACTERS,
} from "@src/constants/form";
import { TFunction } from "i18next";
import * as Yup from "yup";

export const Validation = (t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string()
      .required(`${t("error__name is required")}`)
      .matches(
        /^\S+$/,
        `${t("error__the value should not have any white space")}`
      ),
    title: Yup.string().required(`${t("error__title is required")}`),
    labels: Yup.array()
      .max(
        MAX_LABELS_COUNT,
        `${t("error__the maximum labels that you can add are", {
          max_labels_count: MAX_LABELS_COUNT,
        })}`
      )
      .of(
        Yup.object().shape({
          key: Yup.string()
            .required(`${t("error__key is required")}`)
            .matches(
              /^\S+$/,
              `${t("error__the value should not have any white space")}`
            )
            .min(
              MIN_LABEL_CHARACTERS,
              `${t("error__min character is", {
                min_label_character: MIN_LABEL_CHARACTERS,
              })}`
            )
            .max(
              MAX_LABEL_CHARACTERS,
              `${t("error__max character is", {
                max_label_character: MAX_LABEL_CHARACTERS,
              })}`
            ),
          value: Yup.string()
            .required(`${t("error__value is required")}`)
            .matches(
              /^\S+$/,
              `${t("the value should not have any white space")}`
            )
            .min(
              MIN_LABEL_CHARACTERS,
              `${t("error__min character is", {
                min_label_character: MIN_LABEL_CHARACTERS,
              })}`
            )
            .max(
              MAX_LABEL_CHARACTERS,
              `${t("error__max character is", {
                max_label_character: MAX_LABEL_CHARACTERS,
              })}`
            ),
        })
      ),
  });
};
