import { Button, Grid } from "@mui/material";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TypeInput } from "@src/components/form/type-input";
import { useEffect } from "react";
import { TemplateForm } from "@src/components/form/template-sub-form";
import { TitleWithHelpLink } from "@src/components/form/title-with-help-link";
import { emptyCondition } from "@src/containers/private/constant/empty-condition";
import { AccordionComponent } from "@src/components/accordion/accordion";

interface IProps {
  type: string;
  disabled: boolean;
}
export const ConditionForm = ({ type, disabled }: IProps) => {
  const { t } = useTranslation();
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();
  const {
    fields: conditions,
    append: appendCondition,
    remove: removeCondition,
  } = useFieldArray({
    control,
    name: `${type}.condition`,
  });
  useEffect(() => {
    trigger(`${type}.condition`);
  }, [conditions]);
  const conditionName = useWatch({
    name: `${type}.condition`,
    control,
  });

  return (
    <Grid item xs={10} md={7} data-testid="condition-wrapper">
      <div className="condition-wrapper">
        <Grid item className="label-wrapper">
          <TitleWithHelpLink
            title="page__service__condition"
            error={!!errors[type]?.index?.network_stack_template}
          />
        </Grid>
        {conditions.map((condition, index) => (
          <AccordionComponent
            className="accordion-summary"
            key={condition.id}
            id="condition"
            index={index}
            header={`${t("page__service__condition")}: ${
              conditionName?.[index]?.name || "(__)"
            }`}
            onDelete={removeCondition}
          >
            <Grid
              container
              spacing={2}
              alignItems="center"
              className="label-input"
            >
              <Grid item xs={10} md={7}>
                <TypeInput
                  name={`${type}.condition.${index}.name`}
                  label={t("name")}
                />
              </Grid>
              <Grid item xs={10} md={7}>
                <TypeInput
                  name={`${type}.condition.${index}.where`}
                  label={t("page__service__where")}
                />
              </Grid>
              <Grid item xs={10} md={7}>
                <TypeInput
                  name={`${type}.condition.${index}.exposed_info`}
                  label={t("page__service__exposed info")}
                />
              </Grid>
              <Grid item xs={10} md={7}>
                <TitleWithHelpLink
                  title="page__service__where template"
                  error={!!errors[type]?.condition?.index?.where_template}
                />
              </Grid>
              <TemplateForm
                disabledResp={
                  !!errors?.condition?.[index]?.where_template
                    ?.rest_resp_template?.length
                }
                disabledEnv={
                  !!errors?.condition?.[index]?.where_template?.env?.length
                }
                type={`${type}.condition.${index}.where_template`}
              />
              <Grid item xs={10} md={7}>
                <TitleWithHelpLink
                  title="page__service__template"
                  error={!!errors[type]?.condition?.index?.template}
                />
              </Grid>
              <TemplateForm
                disabledResp={
                  !!errors?.condition?.[index]?.template?.rest_resp_template
                    ?.length
                }
                disabledEnv={
                  !!errors?.condition?.[index]?.template?.env?.length
                }
                type={`${type}.condition.${index}.template`}
              />
            </Grid>
          </AccordionComponent>
        ))}
        <Button
          className="add-button-in-accordion"
          fullWidth
          disabled={disabled}
          onClick={() => {
            appendCondition(emptyCondition);
          }}
        >
          {t("page__service__add condition")}
        </Button>
      </div>
    </Grid>
  );
};
