import React from "react";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { pageSizeOption } from "@src/containers/private/constant/pagination-select-option";
import { IFilterParams } from "@src/services/repository";
import { useTranslation } from "react-i18next";
import { ISelect } from "@src/components/type/select-type";
interface IProps<T> {
  query: T;
  handleChange: (query: T) => void;
  nextState: string;
  prevState: string;
  loading: boolean;
}
export function Footer<T extends IFilterParams>(props: IProps<T>) {
  const { t } = useTranslation();
  const { query, handleChange, nextState, prevState, loading } = props;

  return (
    <>
      <Stack
        data-testid="table-footer-container"
        className="table-footer"
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="right"
      >
        <Typography>{t("number of rows")}:</Typography>
        <FormControl variant="standard" className="select-page-size">
          <Select
            data-testid="pageSizeSelect"
            id="page-size-select"
            value={`${query.page_size || 10}`}
            label="number of rows "
            onChange={(event: SelectChangeEvent) => {
              handleChange({
                ...query,
                page_size: Number(event.target.value),
              });
            }}
          >
            {pageSizeOption.map((option: ISelect, index: number) => (
              <MenuItem key={`pagination_option_${index}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          data-testid="prev_page_button"
          variant="contained"
          disabled={prevState === "" || loading}
          onClick={() => {
            handleChange({
              ...query,
              before: prevState,
              since: null,
            });
          }}
        >
          {t("prev")}
        </Button>
        <Button
          data-testid="next_page_button"
          variant="contained"
          disabled={nextState === "" || loading}
          onClick={() => {
            handleChange({
              ...query,
              before: null,
              since: nextState,
            });
          }}
        >
          {t("next")}
        </Button>
      </Stack>
    </>
  );
}
