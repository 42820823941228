import { Box, Typography, Grid } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { IProject, IProjectFilters } from "@src/models/project.model";
import { useTranslation } from "react-i18next";
import {
  EntityTable,
  IColumn,
  ITableAction,
} from "@src/components/entity_table/entity_table";
import { Helmet } from "react-helmet";
import { ProjectRepository } from "@src/services/project.repository";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { AxiosError } from "axios";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Filter } from "@containers/private/projects/filter";
import { useSearchParams, useLocation } from "react-router-dom";
import {
  colDef,
  removeNullValue,
  updateUrlParamsWithQuery,
} from "@src/helpers/utils";
import { HeaderList } from "@src/containers/private/projects/header";
import { ConfirmModal } from "@src/components/confirm_modal";
import { EMODAL_ACTION } from "@src/constants/enum/confirm-modal-action";
import { ITableBulkAction } from "@src/components/type/tabel-bulk-action-type";
import { useAppSelector } from "@src/redux/hooks";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { EntityNotFoundException } from "@src/services/repository";
import { IReduxState } from "@src/redux/root-reducer";
import { Footer } from "@src/components/list-footer/footer";
import { LIST_PAGE_SIZE } from "@src/containers/private/constant/list-page-size";
import { useNavContext } from "@src/app/navigation-context";

export interface IProps {
  projectRepo?: ProjectRepository;
}
export const ProjectList: React.FC<IProps> = ({ projectRepo }) => {
  const [searchParams] = useSearchParams();
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject.name
  );
  const name = searchParams.get("name");
  const creator = searchParams.get("creator");
  const page_size = Number(searchParams.get("page_size"));
  const since = searchParams.get("since");
  const before = searchParams.get("before");
  const location = useLocation();
  const url = location.pathname;
  const navContext = useNavContext();
  const { t } = useTranslation();
  const [data, setData] = useState<Array<IProject> | null>(null);
  const [repo, setRepo] = useState<ProjectRepository>(
    projectRepo || new ProjectRepository()
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [query, setQuery] = useState<IProjectFilters>({
    name: name,
    creator: creator,
    since: since,
    before: before,
    page_size: page_size || LIST_PAGE_SIZE,
  });
  const [showFilterForm, setShowFilterForm] = useState<boolean>(
    query.name !== null || query.creator !== null
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [bulkSelected, setBulkSelected] = useState<Array<IProject>>([]);
  const [selectedDeleteData, setSelectedDeleteData] = useState<IProject>();
  useEffect(() => {
    setRepo(projectRepo || repo);
  }, [projectRepo != null]);

  const bulkAction: Array<ITableBulkAction> = [
    {
      icon: <DeleteIcon />,
      title: t("delete"),
      isDisabled: false,
      callBack: () => {
        return;
      },
    },
  ];
  const actionList: Array<ITableAction<IProject>> = [
    {
      icon: <DeleteIcon />,
      title: t("delete"),
      action: (data: IProject) => {
        setShowDeleteModal(true);
        setSelectedDeleteData(data);
      },
    },
    {
      icon: <ModeEditIcon />,
      title: t("edit"),
      action: (data: IProject) => {
        if (data.name === selectedProject) {
          toast.error(t("message__you cannot update a selected project"));
        } else {
          navContext(`${EPRIVATEROUTE.PROJECTS}/${data.id}`);
        }
      },
    },
  ];

  const columns: IColumn<IProject>[] = [
    colDef<IProject>("name", t("name")),
    colDef<IProject>("title", t("title")),
    colDef<IProject>("version", t("page__project__version")),
    colDef<IProject>("created_at", t("page__project__created_at"), (_, y) =>
      moment(y.created_at).format("YYYY/MM/DD")
    ),
  ];

  const deleteProject = async () => {
    setLoading(true);
    setError(false);
    try {
      const res = await repo.delete(selectedDeleteData!);
      if (res) {
        toast.success(
          t("message__your request has been successfully completed")
        );
        setRefresh(!refresh);
      }
    } catch (error) {
      if (error instanceof EntityNotFoundException) {
        toast.success(
          t("message__your request has been successfully completed")
        );
        return;
      }
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };
  const applyQuery = async () => {
    setLoading(true);
    setError(false);
    const validQuery = removeNullValue(query);
    try {
      const { data } = await repo.getAll(validQuery);
      setData(data);
    } catch (error) {
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
      if (
        error instanceof AxiosError &&
        (error.message === "Network Error" ||
          error.message === "Request failed with status code 504" ||
          error.message === "Request failed with status code 500")
      ) {
        setError(true);
      }
    } finally {
      setLoading(false);
      const urlParams = new URLSearchParams(location.search);
      const newUrl = updateUrlParamsWithQuery<IProjectFilters>(
        query,
        urlParams,
        url
      );
      if (location.pathname + location.search !== newUrl) {
        navContext(newUrl);
      }
    }
  };

  useEffect(() => {
    applyQuery();
  }, [query, repo, refresh]);

  const toastCantDeleteSelectedProject = () => {
    toast.error(t("message__you cannot delete a selected project"));
    setShowDeleteModal(false);
  };
  const getActionModal = (data: EMODAL_ACTION) => {
    switch (data) {
      case EMODAL_ACTION.YES:
        selectedDeleteData?.name === selectedProject
          ? toastCantDeleteSelectedProject()
          : deleteProject();
        break;
      default:
        setShowDeleteModal(false);
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>{t("page__project__PROJECTS")}</title>
      </Helmet>
      <ToastContainer />
      <div className="projects-container" data-testid="projects-container">
        <HeaderList
          loading={loading}
          handelRefresh={() => setRefresh(!refresh)}
        />
        <Box className="filter-table-content">
          <Grid borderBottom={1} borderColor={"#e0e0e0"}>
            <Grid item className="filter-icon">
              {showFilterForm ? (
                <ArrowDropDownIcon
                  onClick={() => setShowFilterForm(!showFilterForm)}
                />
              ) : (
                <ArrowDropUpIcon
                  onClick={() => setShowFilterForm(!showFilterForm)}
                />
              )}
              <Typography>{t("filter")}</Typography>
            </Grid>
            <Grid item>
              {showFilterForm && (
                <Filter
                  defaultValue={{ ...query }}
                  resetFilter={() => {
                    setQuery({
                      page_size: LIST_PAGE_SIZE,
                      name: null,
                      creator: null,
                    });
                  }}
                  handelFormData={(data: IProjectFilters) => {
                    setQuery({ ...query, ...data });
                  }}
                />
              )}
            </Grid>
          </Grid>
          <EntityTable<IProject>
            bulkeSelectedMessage={
              bulkSelected.length > 1
                ? t("page__project__projects selected", {
                    num: bulkSelected.length,
                  })
                : t("page__project__project selected", {
                    num: bulkSelected.length,
                  })
            }
            selectedItems={bulkSelected}
            onSelectionChange={setBulkSelected}
            showCheckbox={true}
            bulkActions={bulkAction}
            error={error}
            loading={loading}
            tableColumns={columns}
            dataList={data || []}
            actions={actionList}
          />
          {error ? (
            <></>
          ) : (
            <Footer<IProjectFilters>
              query={query}
              loading={loading}
              nextState={repo.getPaginationState().next || ""}
              prevState={repo.getPaginationState().prev || ""}
              handleChange={(query: IProjectFilters) => setQuery(query)}
            />
          )}
        </Box>
      </div>
      <ConfirmModal
        show={showDeleteModal}
        title={t("page__project__delete project", {
          name: selectedDeleteData?.name,
        })}
        text={t("page__project__are you sure to delete project", {
          name: selectedDeleteData?.name,
        })}
        callBack={getActionModal}
        yes={"delete"}
        no={"cancel"}
      />
    </Fragment>
  );
};
