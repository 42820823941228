import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { getCookieLanguages } from "@helpers/utils";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// eslint-disable-next-line
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ["fr", "en"],
    debug: false,
    lng: getCookieLanguages() || "en",
    detection: {
      order: [
        "querystring",
        "subdomain",
        "cookie",
        "localStorage",
        "navigator",
        "htmlTag",
      ],
      caches: ["cookie"],
      cookieMinutes: 160,
      lookupQuerystring: "lang",
      lookupFromPathIndex: 0,
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/i18n/{{lng}}.json",
    },
    nsSeparator: ":",
    keySeparator: "__",
  });
// eslint-disable-next-line
export default i18n;
