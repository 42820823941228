export const FORM_TOAST_TIMEOUT = 4000;
export const MAX_LABELS_COUNT = 10;
export const MAX_LABEL_CHARACTERS = 32;
export const MIN_LABEL_CHARACTERS = 1;
export const MAX_SERVICE_REST_RESP = 100;
export const MAX_SERVICE_ENVIRONMENT = 100;
export const MAX_SERVICE_CONFIGS_COUNT = 100;
export const MAX_SERVICE_PORTS_REMOTE_SERVICES = 100;
export const MAX_SERVICE_PORTS_LISTENING_PORT = 100;
export const MAX_SERVICE_FUNCTIONALITY_COUNT = 100;
export const MAX_SERVICE_MAX_CONDITION_COUNT = 100;
