import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const InformationalSection = () => {
  return (
    <Grid item xs={12} md={6} lineHeight={2}>
      <Box>
        <Typography variant="h4" gutterBottom>
          Monthly Estimate
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui,
          laudantium voluptas optio, ut repellat eum suscipit nulla quidem
        </Typography>
        <Typography variant="subtitle2" lineHeight={2} paddingBottom={2}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum alias
          pariatur dolores commodi facilis modi exercitationem doloremque eum
          deserunt accusantium distinctio temporibus dolor a, expedita
          perferendis quasi reiciendis. Ipsam, sit!
        </Typography>
        <Button variant="text" component={Link} to={""}>
          Compute Engine Pricing
        </Button>
      </Box>
    </Grid>
  );
};
