import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { EPUBLICROUTE } from "@src/constants/enum/public-route.enum";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem/";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { NavLink, useNavigate } from "react-router-dom";
import { useOidc } from "@axa-fr/react-oidc";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";
import { LanguageSelectContainer } from "@src/components/layout/public-layout/public-header/language-select-container";

const pages = [
  EPUBLICROUTE.ABOUTUS,
  EPUBLICROUTE.CONTACTUS,
  EPUBLICROUTE.FAQ,
  EPUBLICROUTE.SAMPLE,
];

export const PublicHeader: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useOidc();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const selectedProjectName = useAppSelector(
    (state: IReduxState) => state.project.selectedProject.name
  );
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page: string) => {
    const validPage = pages.find((p: EPUBLICROUTE) => p === page);
    if (validPage) {
      navigate(`${page}`);
      setAnchorElNav(null);
    }
    setAnchorElNav(null);
  };

  return (
    <div className="publicHeader" data-testid="publicHeader">
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box className="menuBoxXs">
              <IconButton
                aria-label="dashboardIconPublicHeader"
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={`${page}_public_navbar_in_menu`}
                    onClick={() => handleCloseNavMenu(page)}
                  >
                    <Typography textAlign="center">{t(`${page}`)}</Typography>
                  </MenuItem>
                ))}
              </Menu>
              <LanguageSelectContainer />
            </Box>

            <NavLink
              to={
                isAuthenticated
                  ? `${EPRIVATEROUTE.OVERVIEW}?selectedProject=${selectedProjectName}`
                  : EPRIVATEROUTE.OVERVIEW
              }
            >
              <span className="login">
                {isAuthenticated ? t("Dashboard") : t("login")}
              </span>
            </NavLink>
            <NavLink to={EPUBLICROUTE.HOME}>
              <span className="login">{t("home")}</span>
            </NavLink>
            <Box className="menuBoxMd" data-testid="public-headre-list">
              {pages.map((page) => (
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  key={page}
                  to={`${page}`}
                  onClick={() => setAnchorElNav(null)}
                >
                  {t(`${page}`)}
                </NavLink>
              ))}
            </Box>
            <LanguageSelectContainer />
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
