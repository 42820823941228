import { TagsInput } from "react-tag-input-component";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { FormHelperText, Typography } from "@mui/material";
import { Fragment } from "react";

interface IProps {
  label: string;
  name: string;
}
export const FormChipList: React.FC<IProps> = ({ name, label }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const tag_validation = (tag: string, tags: string[]) =>
    tags.length < 10 && tag.length > 0 && tag.length < 32;
  return (
    <Fragment>
      <Typography variant="subtitle2" color="primary">
        {t("message__you have to press inter to each", { label: label })}
      </Typography>
      <Controller
        defaultValue=""
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <div className={fieldState.error ? "input-error-container" : ""}>
              <TagsInput
                value={field.value || []}
                onChange={field.onChange}
                name={name}
                placeHolder={label}
                onBlur={field.onBlur}
                beforeAddValidate={tag_validation}
              />
            </div>
            <FormHelperText error={true}>
              {fieldState.error ? fieldState.error.message : ""}
            </FormHelperText>
          </>
        )}
      />
    </Fragment>
  );
};
