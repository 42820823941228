import {
  Button,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
import HelpIcon from "@mui/icons-material/Help";
import {
  ConflictException,
  EntityNotFoundException,
  IEntityFieldError,
  InvalidEntityFields,
} from "@src/services/repository";
import { FORM_TOAST_TIMEOUT } from "@src/constants/form";
import { colDef, delayCallback, removeNullValue } from "@src/helpers/utils";
import { Validation } from "@src/containers/private/validator/validation";
import {
  NESTED_FIELD,
  ValidatorRepository,
} from "@src/services/validator.repository";
import { IValidator } from "@src/models/validator.model";
import { IReduxState } from "@src/redux/root-reducer";
import { useAppSelector } from "@src/redux/hooks";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { TableSelector } from "@src/components/form/Table-selector";
import { IValidatorProviderWithSelection } from "@src/containers/private/validator/model/validator-provider-with-selection.model";
import CheckIcon from "@mui/icons-material/Check";
import { IColumn } from "@src/components/entity_table/entity_table";
import { IValidatorProvider } from "@src/models/validator-provider.model";
import { ValidatorProviderRepository } from "@src/services/validator-provider.repository";
import { SELECT_LIST_PAGE_SIZE } from "@src/containers/private/constant/table-selector-page-size";
import { IValidatorFormData } from "@src/containers/private/validator/model/form.model";
import { JsonInput } from "@src/components/form/json-input";
import { Loading } from "@src/components/loading";
import { InformationalSection } from "@src/components/form/informational-section";
import { TypeInput } from "@src/components/form/type-input";
import { useNavContext } from "@src/app/navigation-context";
export interface IProps {
  validatorRepo?: ValidatorRepository;
  validatorProviderRepo?: ValidatorProviderRepository;
}

export const CreateValidator: React.FC<IProps> = ({
  validatorRepo,
  validatorProviderRepo,
}) => {
  const { t } = useTranslation();
  const navContext = useNavContext();
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );
  const params = useParams();
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [fetchValidator, setFetchValidator] = useState<IValidator>({
    id: "",
    description: undefined,
    category: null,
    name: "",
    title: "",
    result_schema: {},
    query_parameters_schema: {},
    validator_provider_id: "",
  });

  const [selectedValue, setSelectedValue] =
    useState<IValidatorProviderWithSelection>();
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [repoValidator, setRepoValidator] = useState<ValidatorRepository>(
    validatorRepo || new ValidatorRepository()
  );
  const [providerRepo, setProviderRepo] = useState<ValidatorProviderRepository>(
    validatorProviderRepo || new ValidatorProviderRepository()
  );
  const columns: IColumn<IValidatorProviderWithSelection>[] = [
    colDef<IValidatorProviderWithSelection>("hasSelectIcon", "", (_, y) =>
      y.hasSelectIcon ? <CheckIcon className="check-icon" /> : null
    ),
    colDef<IValidatorProviderWithSelection>("name", t("name")),
    colDef<IValidatorProviderWithSelection>("title", t("title")),
  ];

  const methods = useForm<IValidatorFormData>({
    resolver: yupResolver(Validation(t)),
    mode: "onBlur",
    defaultValues: {
      description: undefined,
    },
  });

  const {
    setValue,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = methods;
  useEffect(() => {
    setProviderRepo(validatorProviderRepo || providerRepo);
  }, [validatorProviderRepo != null]);
  useEffect(() => {
    setRepoValidator(validatorRepo || repoValidator);
  }, [validatorRepo != null]);
  const fetchData = (filter: string) => {
    const newQuery = removeNullValue({
      name: filter,
      page_size: SELECT_LIST_PAGE_SIZE,
      project_id: selectedProject.id,
    });
    return providerRepo.getAll(newQuery);
  };
  const isValid = Object.keys(errors).length == 0;
  const createValidator = async (formData: IValidator) => {
    setLoading(true);

    try {
      await repoValidator.create(formData);
      navContext(EPRIVATEROUTE.VALIDATORS);
    } catch (errors) {
      if (!navigator.onLine) {
        toast.error(t("error__network_error"));
        return;
      }
      if (errors instanceof InvalidEntityFields) {
        errors.detail.map((item: IEntityFieldError<IValidator>) => {
          if (item.loc && item.loc.length > 0 && item.loc[0] === "body") {
            if (item.loc.length === 2) {
              return setError(item.loc[1], { message: item.msg });
            }
          }
        });
        return;
      }
      if (errors instanceof ConflictException) {
        setError("name", {
          message: `${t(
            "error__validator with this name exists, please choose another name"
          )}`,
        });
        return;
      }
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
    } finally {
      setLoading(false);
    }
  };
  const toastAndReturnToList = (
    toastType: "error" | "success",
    message: string,
    params?: Record<string, string>
  ) => {
    if (toastType == "success") {
      toast.success(t(message, params!));
    } else {
      toast.error(t(message, params!));
    }
    delayCallback(
      () => navContext(EPRIVATEROUTE.VALIDATORS),
      FORM_TOAST_TIMEOUT
    );
  };

  const getValidatorById = async (id: string) => {
    setFetchLoading(true);
    try {
      const { data: validator } = await repoValidator.getById(id, [
        NESTED_FIELD.VALIDATOR_PROVIDER,
      ]);
      setFetchValidator(validator);
      setSelectedValue({
        name:
          validator.validator_provider_detail?.name ||
          validator.validator_provider_id,
        id: validator.validator_provider_id,
        hasSelectIcon: true,
        endpoint: "",
        title:
          validator.validator_provider_detail?.title ||
          validator.validator_provider_id,
        project_id: selectedProject.id,
      });
      setValue("name", validator.name);
      setValue("title", validator.title);
      setValue("description", validator.description);
      setValue("category", validator.category);
      setValue("result_schema", JSON.stringify(validator.result_schema));
      setValue(
        "query_parameters_schema",
        JSON.stringify(validator.query_parameters_schema)
      );
      setValue("validator_provider_id", validator.validator_provider_id);
    } catch (errors) {
      if (errors instanceof EntityNotFoundException) {
        toastAndReturnToList(
          "error",
          "message__validator provider is not found",
          {
            id: fetchValidator.id,
          }
        );
        return;
      } else {
        toastAndReturnToList(
          "error",
          "message__an unexpected error happened, please try again after awhile"
        );
      }
    } finally {
      setFetchLoading(false);
    }
  };
  useEffect(() => {
    if (params.validator_id) {
      setEditMode(true);
      getValidatorById(params.validator_id);
    }
  }, [params.validator_id]);

  const editValidator = async (formValues: IValidator) => {
    setLoading(true);
    const newData = { ...fetchValidator, ...formValues };
    try {
      await repoValidator.update(newData);
      navContext(EPRIVATEROUTE.VALIDATORS);
    } catch (errors) {
      if (!navigator.onLine) {
        toast.error(t("error__network_error"));
        return;
      }
      if (errors instanceof InvalidEntityFields) {
        errors.detail.map((item: IEntityFieldError<IValidator>) => {
          if (item.loc && item.loc.length > 0 && item.loc[0] === "body") {
            if (item.loc.length === 2) {
              return setError(item.loc[1], { message: item.msg });
            }
          }
        });
        return;
      }
      if (errors instanceof ConflictException) {
        setError("name", {
          message: `${t(
            "error__validator provider with this name exist,please choose another name"
          )}`,
        });
        return;
      }
      if (errors instanceof EntityNotFoundException) {
        toastAndReturnToList(
          "error",
          "message__validator provider is not found",
          {
            id: fetchValidator.id,
          }
        );
        return;
      } else {
        toast.error(
          t(
            "message__an unexpected error happened, please try again after awhile"
          )
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const onSubmit = (formValues: IValidatorFormData) => {
    const formData = {
      ...formValues,
      query_parameters_schema:
        formValues.query_parameters_schema &&
        JSON.parse(formValues.query_parameters_schema),
      result_schema:
        formValues.result_schema && JSON.parse(formValues.result_schema),
    };
    if (editMode) {
      editValidator(formData);
    } else {
      createValidator(formData);
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>{t("create")}</title>
      </Helmet>
      <ToastContainer autoClose={FORM_TOAST_TIMEOUT} />
      {fetchLoading ? (
        <Loading />
      ) : (
        <div className="create-validator-wrapper">
          <Grid container spacing={3} data-testid="create-validator-wrapper">
            <Grid item xs={12} md={6}>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2} rowSpacing={3} direction="column">
                    <Grid item>
                      <TypeInput
                        name="name"
                        label={t("name")}
                        InputProps={{
                          readOnly: editMode,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Link to="">
                                <IconButton color="info">
                                  <HelpIcon />
                                </IconButton>
                              </Link>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TypeInput label={t("title")} name="title" />
                    </Grid>
                    <Grid item>
                      <TypeInput
                        label={t("page__validator__category")}
                        name="category"
                      />
                    </Grid>
                    <Grid item>
                      {editMode && (
                        <TextField
                          label={t(
                            "page__validatorProvider__validatorProvider"
                          )}
                          id="validator_provider_id-validator"
                          placeholder={
                            t("page__validatorProvider__validatorProvider")!
                          }
                          type="text"
                          variant="outlined"
                          fullWidth
                          value={selectedValue?.name || selectedValue?.id}
                          InputProps={{
                            readOnly: editMode,
                          }}
                        />
                      )}
                      <Controller
                        control={control}
                        name="validator_provider_id"
                        render={({ field: { onChange, onBlur } }) => (
                          <>
                            {!editMode && (
                              <TableSelector<IValidatorProviderWithSelection>
                                onBlur={onBlur}
                                fieldError={
                                  errors?.validator_provider_id?.message
                                }
                                renderDisplayValue={(
                                  value: IValidatorProvider
                                ) => {
                                  return value?.name || value?.id || "";
                                }}
                                fetchData={(filter: string) =>
                                  fetchData(filter)
                                }
                                columns={columns}
                                onChange={(
                                  data: IValidatorProviderWithSelection
                                ) => {
                                  onChange(data.id);
                                  setSelectedValue(data);
                                }}
                                selectedValue={selectedValue!}
                                label={t(
                                  "page__validatorProvider__validatorProvider"
                                )}
                              />
                            )}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <TypeInput
                        multiline
                        label={t("description")}
                        rows={3}
                        name="description"
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        name="query_parameters_schema"
                        render={({ field: { onChange, value, onBlur } }) => (
                          <JsonInput
                            onBlur={onBlur}
                            fieldError={
                              errors?.query_parameters_schema?.message
                            }
                            onChange={(value: string) => {
                              onChange(value);
                            }}
                            value={value}
                            label="page__validator__query_parameters_schema"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        name="result_schema"
                        render={({ field: { onChange, value, onBlur } }) => (
                          <JsonInput
                            onBlur={onBlur}
                            fieldError={errors?.result_schema?.message}
                            onChange={(value: string) => {
                              onChange(value);
                            }}
                            value={value}
                            label="page__validator__result_schema"
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <Button
                        style={{ marginRight: "4px" }}
                        data-testid="create-form-validator"
                        disabled={!isValid || loading}
                        type="submit"
                        variant="contained"
                      >
                        {loading
                          ? t("loading")
                          : editMode
                          ? t("edit")
                          : t("create")}
                      </Button>
                      <Button
                        variant="outlined"
                        component={Link}
                        to={`${EPRIVATEROUTE.VALIDATORS}?selectedProject=${selectedProject.name}`}
                      >
                        {t("cancel")}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </Grid>
            <InformationalSection />
          </Grid>
        </div>
      )}
    </Fragment>
  );
};
