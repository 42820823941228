import { ISelect } from "@src/components/type/select-type";

export const stringOrTemplateChoicesModel: Array<ISelect> = [
  { value: "false", label: "type string" },
  { value: "true", label: "type template definition" },
];
export const typeOptions: Array<ISelect> = [
  { value: "jinja2", label: "jinja2" },
  { value: "script/python", label: "script/python" },
  { value: "script/js", label: "script/js" },
  { value: "rest_api/jinja2", label: "rest_api/jinja2" },
];
export const from_sectionOptions: Array<ISelect> = [
  { value: "variables", label: "variables" },
  { value: "configs", label: "configs" },
  { value: "connections", label: "connections" },
];
