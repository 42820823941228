import {
  IServiceDefinition,
  IServiceDefinitionFilter,
} from "@src/models/service-definition.model";
import { Repository } from "@src/services/repository";

export class ServiceDefinitionRepository extends Repository<
  IServiceDefinition,
  IServiceDefinition,
  IServiceDefinition,
  IServiceDefinition,
  IServiceDefinitionFilter
> {
  constructor() {
    super("/v1.0/service_definition");
  }
}
