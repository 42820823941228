import { IEntityModel } from "@src/models/entity-model.model";
import qs from "qs";

import {
  IHttpClient,
  IHttpResponse,
  defaultSimpleHttpClient,
} from "@src/helpers/http_client";

export interface IMetaProperties {
  next_page_link?: string;
  prev_page_link?: string;
}

export interface IResult<T> {
  meta: IMetaProperties;
  data: T;
}
export interface IFilterParams {
  page_size?: number | null;
  before?: string | null;
  since?: string | null;
  nested_fields?: Array<string> | null;
}
export interface IPagination {
  next?: string | null;
  prev?: string | null;
}
export interface IEntityFieldError<T extends IEntityModel> {
  loc:
    | ["body", ...(keyof T)[]]
    | ["path", ...string[]]
    | ["query", ...string[]];
  msg: string;
  type: string;
}

export interface IUnProcessableEntityError<T extends IEntityModel> {
  detail: Array<IEntityFieldError<T>>;
}
export interface IRepository<
  TFetch extends IEntityModel,
  TCreate extends IEntityModel,
  TUpdate extends IEntityModel,
  TDelete extends IEntityModel,
  F extends IFilterParams
> {
  create(data: TCreate): Promise<IResult<TFetch>>;
  update(data: TUpdate): Promise<IResult<TFetch>>;
  getById(id: string): Promise<IResult<TFetch>>;
  delete(data: TDelete): Promise<IResult<TFetch>>;
  getAll(filter?: F): Promise<IResult<Array<TFetch>>>;
}

export class RepositoryException extends Error {
  constructor(message: string) {
    super(message);
  }
}
export class EntityNotFoundException<T extends IEntityModel>
  extends RepositoryException
  implements IUnProcessableEntityError<T>
{
  detail: IEntityFieldError<T>[];
  constructor(fieldsList: IUnProcessableEntityError<T>) {
    super("not found");
    this.detail = fieldsList.detail;
  }
}

export class ConflictException extends RepositoryException {
  constructor() {
    super("conflict");
  }
}

export class InvalidEntityFields<T extends IEntityModel>
  extends RepositoryException
  implements IUnProcessableEntityError<T>
{
  detail: IEntityFieldError<T>[];
  constructor(fieldsList: IUnProcessableEntityError<T>) {
    super("invalid entity fields");
    this.detail = fieldsList.detail;
  }
}

const default_page_size: IFilterParams = { page_size: 10 };

export class Repository<
  T extends IEntityModel,
  TCreate extends IEntityModel,
  TUpdate extends IEntityModel,
  TDelete extends IEntityModel,
  F extends IFilterParams
> implements IRepository<T, TCreate, TUpdate, TDelete, F>
{
  url: string;
  baseUrl: string;
  headers: Map<string, string>;
  http_client: IHttpClient;
  next_url?: string;
  prev_url?: string;
  current_url?: string;
  constructor(url: string, http_client?: IHttpClient) {
    this.http_client = http_client || defaultSimpleHttpClient;
    this.url = `${
      process.env.REACT_APP_TARGET !== "local"
        ? process.env.REACT_APP_BASE_URL
        : ""
    }${url}`;
    this.baseUrl =
      process.env.REACT_APP_TARGET !== "local"
        ? process.env.REACT_APP_BASE_URL || ""
        : "";

    this.headers = new Map<string, string>([
      ["Content-Type", "application/json;utf-8"],
    ]);
    this.next_url = "";
    this.prev_url = "";
    this.current_url = "";
  }
  raiseExceptionOnError(
    response: IHttpResponse<
      IResult<T> | IResult<Array<T>> | IUnProcessableEntityError<T>
    >
  ): void {
    switch (response.status) {
      case 404:
        throw new EntityNotFoundException(
          response.body as IUnProcessableEntityError<T>
        );
      case 409:
        throw new ConflictException();
      case 422:
        throw new InvalidEntityFields(
          response.body as IUnProcessableEntityError<T>
        );
    }
  }
  async create(data: TCreate): Promise<IResult<T>> {
    const url = this.url;

    const rtn = await this.http_client.post<TCreate, IResult<T>>(
      url,
      data,
      this.headers
    );
    this.raiseExceptionOnError(rtn);
    return rtn.body;
  }

  async update(data: TUpdate): Promise<IResult<T>> {
    const url = `${this.url}/${data.id}`;

    const rtn = await this.http_client.put<TUpdate, IResult<T>>(
      url,
      data,
      this.headers
    );
    this.raiseExceptionOnError(rtn);
    return rtn.body;
  }

  async getById(id: string): Promise<IResult<T>> {
    const url = `${this.url}/${id}`;
    const rtn = await this.http_client.get<IResult<T>>(url, this.headers);
    this.raiseExceptionOnError(rtn);
    return rtn.body;
  }

  async getAll(filter: F = default_page_size as F): Promise<IResult<Array<T>>> {
    if (!filter.nested_fields || filter.nested_fields.length === 0) {
      delete filter.nested_fields;
    }
    const url = `${this.url}?${qs.stringify(filter, { indices: false })}`;

    this.current_url = url;
    const rtn = await this.http_client.get<IResult<Array<T>>>(
      url,
      this.headers
    );
    this.raiseExceptionOnError(rtn);
    this.next_url = rtn.body.meta.next_page_link || "";
    this.prev_url = rtn.body.meta.prev_page_link || "";
    return rtn.body;
  }
  async refresh(
    filter: F = default_page_size as F
  ): Promise<IResult<Array<T>>> {
    const newUrl = this.current_url?.replaceAll(
      /(page_size=)\d+/g,
      `$1${filter?.page_size}`
    );

    const filterUrl = newUrl!;
    const rtn = await this.http_client.get<IResult<Array<T>>>(
      filterUrl,
      this.headers
    );
    this.raiseExceptionOnError(rtn);
    this.next_url = rtn.body.meta.next_page_link || "";
    this.prev_url = rtn.body.meta.prev_page_link || "";
    this.current_url = filterUrl;
    return rtn.body;
  }
  async delete(data: TDelete): Promise<IResult<T>> {
    const url = `${this.url}/${data.id}/${data.version}`;
    const rtn = await this.http_client.delete<IResult<T>>(url, this.headers);
    this.raiseExceptionOnError(rtn);
    return rtn.body;
  }
  async next(): Promise<IResult<Array<T>>> {
    const url = this.next_url!;
    const rtn = await this.http_client.get<IResult<Array<T>>>(
      url,
      this.headers
    );
    this.raiseExceptionOnError(rtn);
    this.next_url = rtn.body.meta.next_page_link || "";
    this.prev_url = rtn.body.meta.prev_page_link || "";
    this.current_url = url;

    return rtn.body;
  }
  async prev(): Promise<IResult<Array<T>>> {
    const url = this.prev_url!;
    const rtn = await this.http_client.get<IResult<Array<T>>>(
      url,
      this.headers
    );
    this.raiseExceptionOnError(rtn);
    this.next_url = rtn.body.meta.next_page_link || "";
    this.prev_url = rtn.body.meta.prev_page_link || "";
    this.current_url = url;

    return rtn.body;
  }
  getPaginationState(): IPagination {
    const regexSince = /since=(\d+)/;
    const matchSince = this.next_url?.match(regexSince);
    const sinceValue = matchSince ? matchSince[1] : null;
    const regexPrev = /before=(\d+)/;
    const matchPrev = this.prev_url?.match(regexPrev);
    const prevValue = matchPrev ? matchPrev[1] : null;
    return {
      next: sinceValue,
      prev: prevValue,
    };
  }
}
