import { Button, CardContent, Grid, Stack } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import * as Yup from "yup";
import React, { Fragment, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { TableSelector } from "@src/components/form/Table-selector";
import { colDef, removeNullValue } from "@src/helpers/utils";
import { IColumn } from "@src/components/entity_table/entity_table";
import { IReduxState } from "@src/redux/root-reducer";
import { useAppSelector } from "@src/redux/hooks";
import { ToastContainer, toast } from "react-toastify";
import { IValidatorWithSelection } from "@src/containers/private/type/model/validator-with-selection.model";
import { SELECT_LIST_PAGE_SIZE } from "@src/containers/private/constant/table-selector-page-size";
import { ValidatorRepository } from "@src/services/validator.repository";
import { ITypeFilter } from "@src/models/type.model";
import { TypeInput } from "@src/components/form/type-input";

interface IProps {
  validatorRepo?: ValidatorRepository;
  handelQuery: (data: ITypeFilter) => void;
  defaultValue: ITypeFilter;
}

export const Filter: React.FC<IProps> = ({
  validatorRepo,
  handelQuery,
  defaultValue,
}) => {
  const schema = Yup.object().shape({});
  const methods = useForm<ITypeFilter>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: defaultValue,
  });
  const {
    getValues,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;
  const { t } = useTranslation();
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );
  const [repo, setRepo] = useState<ValidatorRepository>(
    validatorRepo || new ValidatorRepository()
  );
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const fetchData = (filter: string) => {
    const newQuery = removeNullValue({
      name: filter,
      page_size: SELECT_LIST_PAGE_SIZE,
      project_id: selectedProject.id,
    });
    return repo.getAll(newQuery);
  };
  const initialSelectValue = {
    project_id: selectedProject.id,
    name: "",
    title: "",
    result_schema: {},
    query_parameters_schema: {},
    id: getValues("validator_id") || "",
    hasSelectIcon: true,
    validator_provider_id: "",
  };

  const [selectedValue, setSelectedValue] =
    useState<IValidatorWithSelection>(initialSelectValue);
  const columns: IColumn<IValidatorWithSelection>[] = [
    colDef<IValidatorWithSelection>("hasSelectIcon", "", (_, y) =>
      y.hasSelectIcon ? <CheckIcon className="check-icon" /> : null
    ),
    colDef<IValidatorWithSelection>("name", t("name")),
    colDef<IValidatorWithSelection>("title", t("title")),
  ];

  const validator_id = defaultValue.validator_id;
  const getValidatorById = async (id: string) => {
    setFetchLoading(true);
    try {
      const { data: validatorPro } = await repo.getById(id);
      validatorPro &&
        setSelectedValue({
          ...validatorPro,
          id: validator_id!,
          hasSelectIcon: true,
        });
    } catch (errors) {
      toast.error(
        t(
          "message__an unexpected error happened, please try again after awhile"
        )
      );
    } finally {
      setFetchLoading(false);
    }
  };
  useEffect(() => {
    if (validator_id && selectedValue.name === "") {
      getValidatorById(validator_id);
    }
  }, [validator_id]);

  useEffect(() => {
    setRepo(validatorRepo || repo);
  }, [validatorRepo != null]);

  const isValid = Object.keys(errors).length == 0;
  const onSubmit = (data: ITypeFilter) => {
    handelQuery({
      ...defaultValue,
      ...data,
    });
  };
  return (
    <Fragment>
      <ToastContainer />
      <Stack
        data-testid="type-filter-container"
        className="filter-form-wrapper"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <CardContent>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                direction="column"
              >
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="validator_id"
                      render={({ field: { onChange } }) => (
                        <TableSelector<IValidatorWithSelection>
                          renderDisplayValue={(
                            value: IValidatorWithSelection
                          ) => {
                            return value.name || value.id;
                          }}
                          fetchData={(filter: string) => fetchData(filter)}
                          columns={columns}
                          onChange={(data: IValidatorWithSelection) => {
                            onChange(data.id);
                            setSelectedValue(data);
                          }}
                          selectedValue={selectedValue}
                          label={t("page__validator__validator")}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TypeInput label={t("name")} name="name" />
                  </Grid>
                  <Grid item xs={6}>
                    <TypeInput label={t("title")} name="title" />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="end"
                    alignItems="center"
                  >
                    <Button
                      className="button-margin-submit"
                      data-testid="submit_button"
                      disabled={!isValid || fetchLoading}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {t("filter")}
                    </Button>

                    <Button
                      className="button-margin"
                      data-testid="reset_button"
                      variant="contained"
                      disabled={!isValid || fetchLoading}
                      color="primary"
                      onClick={() => {
                        const resetValue = {
                          name: null,
                          title: null,
                          validator_id: null,
                        };
                        reset(resetValue);
                        setSelectedValue({ ...initialSelectValue, id: "" });
                        handelQuery({ ...defaultValue, ...resetValue });
                      }}
                    >
                      {t("reset")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </CardContent>
      </Stack>
    </Fragment>
  );
};
