import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { setSelectLanguage } from "@src/app/language-slice";
import { LanguagePopover } from "@src/components/layout/public-layout/public-header/language-popover";
import { IReduxState } from "@src/redux/root-reducer";

export const LanguageSelectContainer: React.FC<{}> = () => {
  const { i18n } = useTranslation();
  const lang = useAppSelector((state: IReduxState) => state.language.language);
  const [language, setLanguage] = useState<string>(lang);
  const dispatch = useAppDispatch();
  useEffect(() => {
    i18n.changeLanguage(language);
    dispatch(setSelectLanguage(language));
  }, [language]);

  return <LanguagePopover value={language} handleChange={setLanguage} />;
};
