/* eslint-disable   @typescript-eslint/no-explicit-any */
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import {
  TYPE_NESTED_FIELD,
  TypeRepository,
} from "@src/services/type.repository";
import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@mui/material";
import { TableSelector } from "@src/components/form/Table-selector";
import { ITypeWithSelection } from "@containers/private/service-definition/model/type-with-selection";
import {
  colDef,
  removeNullValue,
  updateSelectedTypeAtIndex,
} from "@src/helpers/utils";
import { useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";
import { IColumn } from "@src/components/entity_table/entity_table";
import { TypeInput } from "@src/components/form/type-input";
import { TitleWithHelpLink } from "@src/components/form/title-with-help-link";
import { emptyVariable } from "@src/containers/private/constant/empty-variable";
import { AccordionComponent } from "@src/components/accordion/accordion";
import { VariablesValueTemplate } from "@src/components/form/variable-value-template-sub-form";
import { IMinimalWithConfigAndVariables } from "@src/models/minimal-with-config-variable.model";
export interface IProps<T> {
  typeRepo?: TypeRepository;
  editMode?: boolean;
  model: T;
}
export function Variables<T extends IMinimalWithConfigAndVariables>(
  props: IProps<T>
) {
  const { typeRepo, model, editMode = false } = props;
  const [repo, setRepo] = useState<TypeRepository>(
    typeRepo || new TypeRepository()
  );
  const { t } = useTranslation();
  const selectedProject = useAppSelector((state: IReduxState) => {
    return state.project.selectedProject;
  });
  const [selectedType, setSelectedType] = useState<
    | {
        value: ITypeWithSelection;
        index: number;
      }[]
    | null
  >(null);
  const {
    trigger,
    control,
    formState: { errors },
  } = useFormContext();

  const {
    fields: variables,
    append: appendVariables,
    remove: removeVariables,
  } = useFieldArray({
    control,
    name: "variables",
  });
  useEffect(() => {
    trigger("variables");
  }, [variables]);
  useEffect(() => {
    setRepo(typeRepo || repo);
  }, [typeRepo != null]);
  const fetchTypes = (filter: string) => {
    const newQuery = removeNullValue({
      name: filter,
      project_id: selectedProject.id,
      nestedFields: [TYPE_NESTED_FIELD.VALIDATOR],
    });
    return repo.getAll(newQuery);
  };

  const typeDropdownCols: IColumn<ITypeWithSelection>[] = [
    colDef("hasSelectIcon", "", (_, y) =>
      y.hasSelectIcon ? <CheckIcon className="check-icon" /> : null
    ),
    colDef("name", t("name")),
    colDef("title", t("title")),
  ];
  const variableName = useWatch({
    name: "variables",
    control,
  });
  return (
    <div className="variable-wrapper" data-testid="variable-wrapper">
      <TitleWithHelpLink
        title="page__service__variable"
        error={!!errors.variables?.length}
      />
      {variables.map((variable, index) => (
        <AccordionComponent
          key={variable.id}
          id="variable"
          index={index}
          header={`${t("page__service__variable")}: ${
            variableName?.[index]?.name || "(__)"
          }`}
          onDelete={removeVariables}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="label-input"
          >
            <Grid item xs={10} md={7}>
              <TypeInput name={`variables.${index}.name`} label={t("name")} />
            </Grid>
            <Grid item xs={10} md={7}>
              {editMode && (
                <TextField
                  label={t("page__service__type")}
                  id={`service-variables-${index}-type`}
                  placeholder={t("page__service__type")!}
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={model?.variables?.[index].type}
                  InputProps={{
                    readOnly: editMode,
                  }}
                />
              )}
              <Controller
                name={`variables.${index}.type`}
                control={control}
                render={({ field: { onChange, onBlur } }) => (
                  <>
                    {!editMode && (
                      <TableSelector<ITypeWithSelection>
                        onBlur={onBlur}
                        fieldError={
                          errors?.variables?.[index]?.type?.message as any
                        }
                        renderDisplayValue={(value) => {
                          return value?.name || value?.id || "";
                        }}
                        fetchData={fetchTypes}
                        columns={typeDropdownCols}
                        onChange={(data: ITypeWithSelection) => {
                          onChange(data.name);
                          setSelectedType((prevSelectedType) =>
                            updateSelectedTypeAtIndex<ITypeWithSelection>(
                              prevSelectedType,
                              data,
                              index
                            )
                          );
                        }}
                        selectedValue={
                          selectedType && selectedType[index]
                            ? selectedType[index].value
                            : null
                        }
                        label={t("page__service__type")}
                      />
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={10} md={7}>
              <VariablesValueTemplate index={index} />
            </Grid>
          </Grid>
        </AccordionComponent>
      ))}
      <Button
        className="add-button-in-accordion"
        fullWidth
        disabled={!!errors?.variables?.length}
        onClick={() => {
          appendVariables(emptyVariable);
        }}
      >
        {t("page__service__add variable")}
      </Button>
    </div>
  );
}
