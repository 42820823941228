import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectProps } from "@mui/material/Select";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ISelect } from "@components/type/select-type";
interface IProps extends SelectProps {
  options: Array<ISelect>;
  name: string;
  label: string;
}
export function FormSelect(props: IProps) {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const { name, label, options } = props;

  return (
    <>
      <Controller
        {...props}
        defaultValue=""
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth data-testid="form-select-component">
            <InputLabel
              id={`form-select-label-${label}`}
              data-testid={label}
              aria-invalid={!!error}
              error={!!error}
            >
              {t(label)}
            </InputLabel>
            <Select
              {...field}
              label={t(label)}
              placeholder={t(label)!}
              fullWidth
              labelId={`form-select-label-${label}`}
              id={label}
              value={field.value || ""}
              data-testid={label}
              aria-invalid={!!error}
              error={!!error}
            >
              {options.map((option: ISelect, index: number) => (
                <MenuItem key={`${label}_${index}`} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <div
              data-testid="error"
              className={`${error?.message ? "wizard-error" : ""}`}
            >
              {error?.message ? error.message : ""}
            </div>
          </FormControl>
        )}
      />
    </>
  );
}
