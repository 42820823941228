import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Validation } from "@containers/private/projects/validation";
import { useTranslation } from "react-i18next";
import { Button, Grid, InputAdornment, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import { TypeInput } from "@src/components/form/type-input";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";
import { IEntityFieldError } from "@src/services/repository";
import { IProject } from "@src/models/project.model";
import { TagChipList } from "@src/components/form/tag-chip-list";
import { IProjectFormData } from "./model/form.model";
import { convertObjToArray } from "@src/helpers/utils";
import { LabelArray } from "@src/components/form/label-array";

export interface IProps {
  onSubmit: (formValues: IProjectFormData) => void;
  editMode: boolean;
  loading: boolean;
  model: IProject | null;
  errorList: Array<IEntityFieldError<IProject>> | null;
}

export const SLProjectForm: React.FC<IProps> = ({
  onSubmit,
  editMode,
  loading,
  model,
  errorList,
}) => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );

  const methods = useForm<IProjectFormData>({
    resolver: yupResolver(Validation(t)),
    mode: "onBlur",
    defaultValues: {
      description: null,
      tags: [],
    },
  });
  const {
    control,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (editMode && model) {
      const labelsObject = model.labels || {};
      const convertedLabelsArray = convertObjToArray(labelsObject);
      setValue("name", model.name);
      setValue("title", model.title);
      setValue("description", model.description);
      setValue("icon", model.icon);
      setValue("tags", model.tags);
      setValue("labels", convertedLabelsArray);
    }
  }, []);

  useEffect(() => {
    if (errorList) {
      errorList.map(
        (item) =>
          item.loc[0] == "body" && setError(item.loc[1]!, { message: item.msg })
      );
    }
  }, [errorList]);

  const isValid = Object.keys(errors).length == 0;
  const btnTitle = editMode ? t("edit") : t("create");

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          rowSpacing={3}
          direction="column"
          data-testid="stateless-form-component"
        >
          <Grid item>
            <TypeInput
              name="name"
              label={t("name")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Link to="">
                      <IconButton color="info">
                        <HelpIcon />
                      </IconButton>
                    </Link>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <TypeInput label={t("title")} name="title" />
          </Grid>
          <Grid item>
            <TypeInput
              name="description"
              label={t("description")}
              multiline
              rows={3}
            />
          </Grid>
          <Grid item>
            <TypeInput name="icon" label={t("page__project__icon")} />
          </Grid>

          <Grid item>
            <Controller
              control={control}
              name="tags"
              render={({ field: { onChange, value, name } }) => (
                <TagChipList onChange={onChange} name={name} tags={value!} />
              )}
            />
          </Grid>
          <LabelArray />
          <Grid
            item
            container
            direction="row"
            justifyContent="start"
            alignItems="center"
          >
            <Button
              type="submit"
              style={{ marginRight: "4px" }}
              data-testid="create-form-project"
              disabled={!isValid || loading}
              variant="contained"
            >
              {loading ? t("loading") : btnTitle}
            </Button>

            <Button
              variant="outlined"
              component={Link}
              to={`${EPRIVATEROUTE.PROJECTS}?selectedProject=${selectedProject.name}`}
            >
              {t("cancel")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
