import React from "react";
import { Container, Stack, Typography } from "@mui/material";
import empty from "@src/assets/images/empty-box.svg";
import { useTranslation } from "react-i18next";

export function Empty() {
  const { t } = useTranslation();

  return (
    <Container>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <img width="6%" height="6%" src={empty} alt="empty-data" />
        <Typography data-testid="empty-data-message">{t("no data")}</Typography>
      </Stack>
    </Container>
  );
}
